export const SUCCESS_RESPONE = 'Success';
export const SUCCESS_NOTICE = 'thành công';
export const FAILURE_NOTICE = 'Thất bại';
export const TITLE_NOTICE = 'Thông báo';

export const PAGE_SIZE = 10;
export const PAGE_SIZE_HOME = 3;

export const WIDTH_DIALOG = '80%';
export const HEIGHT_DIALOG = '100%';
export const Action = {
  Create: 'Thêm mới ',
  Update: 'Cập nhật ',
  Detail: 'Xem chi tiết ',
  Import: 'Import ',
  Role: 'Phân quyền ',
};
export const LBL_DISPLAY = {
  add: 'Thêm mới',
  addRow: 'Thêm dòng mới',
  edit: 'Sửa',
  update: 'Cập nhập',
  delete: 'Xóa',
  view: 'Xem',
  search: 'Tìm kiếm',
  save: 'Lưu',
  phone: 'Tư vấn',
  print: 'In ',
  close: 'Đóng',
  back: 'Quay lại',
  refresh: 'Làm mới',
  file: 'Chọn file',
  confirm: 'Xác nhận',
  cancel: 'Hủy',
  importFile: 'Nhập Excel',
  exportFile: 'Xuất Excel',
  exportPdf: 'Xuất PDF',
  downloadTemplate: 'Tải template',
  refuse: 'Từ chối',
  confirmAll: 'Thực hiện với tất cả dữ liệu.',
  confirmSelect: 'Thực hiện với dữ liệu đã chọn.',
  confirmDelete: 'Bạn chắc chắn muốn xóa dữ liệu?.',
  calendar: 'Đặt lịch',
  detail: 'Xem chi tiết',
  resetPassWord: 'Khôi phục mật khẩu mặc định',
  confirmResetPassWord: 'Bạn chắc chắn muốn khôi phục mật khẩu mặc định',
  confirmUpload: 'Bạn có muốn xóa các file cũ không?',
};
