import { NgxSpinnerModule } from 'ngx-spinner';
import { LoginRoutingModule } from './login-routing.module';
import { FormsModule } from '@angular/forms';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { NbLayoutModule, NbToastrModule } from '@nebular/theme';
import { LoginLayoutComponent } from './login-layout.component';

@NgModule({
  imports: [
    FormsModule,
    NbToastrModule,
    NbLayoutModule,
    NgxSpinnerModule,
    LoginRoutingModule,
  ],
  exports: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  declarations: [LoginLayoutComponent],
})
export class LoginLayoutModule {}
