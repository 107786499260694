import { Component, OnInit } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { MenuService } from './menu.service';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { MenuModel } from 'src/app/core/services/qtht.service';

@Component({
  selector: 'ngx-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['menu.component.scss'],
})
export class MenuComponent implements OnInit {
  menu: NbMenuItem[] = [];

  constructor(private menuService: MenuService) {}

  ngOnInit(): void {
    let checkLoadMenu = false;
    this.menuService.resetMenuWhenLogin();
    this.menuService.getNbMenuItem().subscribe((result) => {
      if (result.length !== 0 || checkLoadMenu) {
        this.menu = result;
      } else {
        this.menuService.getMenuByUserLogin().subscribe((rest) => {
          if (rest.code === SUCCESS_RESPONE) {
            this.loadMenu(rest.data ?? []);
            this.menuService.setRoutingSaveCheckRole(rest.data ?? []);
            checkLoadMenu = true;
          } else {
            checkLoadMenu = false;
          }
        });
      }
    });
  }

  loadMenu(lst: MenuModel[]) {
    lst
      .filter((c) => c.idMenuParent === null || c.idMenuParent === undefined)
      .forEach((element) => {
        const itemMenu = new NbMenuItem();
        itemMenu.title = element.menuName ?? '';
        itemMenu.link = element.pathMenu;
        if (lst.findIndex((c) => c.idMenuParent === element.menuId) !== -1) {
          itemMenu.children = this.loadMenuChildrent(element, lst);
        }
        this.menu.push(itemMenu);
      });
    this.menuService.setNbMenuItem(this.menu);
  }

  loadMenuChildrent(item: MenuModel, lst: MenuModel[]): NbMenuItem[] {
    const result: NbMenuItem[] = [];
    lst
      .filter((c) => c.idMenuParent === item.menuId)
      .forEach((element) => {
        const itemChilrent = new NbMenuItem();
        itemChilrent.title = element.menuName ?? '';
        itemChilrent.link = element.pathMenu;
        if (lst.findIndex((t) => t.idMenuParent === element.menuId) !== -1) {
          itemChilrent.children = this.loadMenuChildrent(element, lst);
        }
        result.push(itemChilrent);
      });
    return result;
  }
}
