import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Action,
  LBL_DISPLAY,
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from 'src/app/core/customs/constants';
import { SysDanhGiaService } from '../sysDanhGia.service';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { DialogConfirmComponent } from 'src/app/theme/components/template/button/dialog/dialog-confirm/dialog-confirm.component';
import { environment } from 'src/environments/environment';
import { SysDanhGiaCrudComponent } from '../sysDanhGia-crud/sysDanhGia-crud.component';
import {
  DanhGiaModel,
  DanhGiaModelListPageModelView,
} from 'src/app/core/services/vcvn.service';
import { SysPageModel } from 'src/app/core/services/qtht.service';
@Component({
  selector: 'ngx-sysDanhGia-index',
  templateUrl: './sysDanhGia-index.component.html',
  styleUrls: ['./sysDanhGia-index.component.scss'],
})
export class SysDanhGiaIndexComponent implements OnInit {
  constructor(
    private service: SysDanhGiaService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}
  title = 'Quản lý đánh giá, nhận xét';
  target1: any = {
    data: [],
  };
  unit: any;
  pageModel1: SysPageModel = new SysPageModel({
    search: '',
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: 'record',
    sortColumn: [],
    searchColumn: [],
  });
  lstSanPham: any = [];
  lstRecord: any = [];
  ngOnInit(): void {
    this.loadData1();
    this.service.getUnitInfo().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.unit = rest.data;
      }
    });
    this.service.selectAllRecordBase().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.lstRecord = rest.data;
      }
    });
  }
  getTenRecord(recordIdId: Number) {
    return this.lstRecord.find(
      (c: { recordIdId: Number }) => c.recordIdId === recordIdId
    )?.tieuDe;
  }

  getSTT1(index: number) {
    return (
      (this.pageModel1.pageSize ?? 0) *
        ((this.pageModel1.currentPage ?? 1) - 1) +
      index +
      1
    );
  }

  downLoadTemplate() {
    this.commonService.downloadFileTemPlate('SysDanhGia');
  }
  genTable(data: any, menuName?: string) {
    let body = `<table class="table table-bordered table-hover" id="customers">
    <thead>
      <tr>
        <th class="text-center">STT</th>
        <th class="text-center">Tên</th>
        <th class="text-center">Số điện thoại</th>
        <th class="text-center">Bài viết</th>
        <th class="text-center">Đánh giá</th>
        <th class="text-center">Nhận xét</th>
        <th class="text-center">Trạng thái</th>
        <th class="text-center">Ngày tạo</th>
        <th class="text-center">Ngày cập nhật</th>
      </tr>
    </thead>
    <tbody>`;
    if (data.length === 0) {
      body = `<tr><td colspan="3">Không có dữ liệu</td></tr>`;
    } else {
      data.forEach(
        (
          item: {
            ten: string;
            sdt: string;
            modelId: number;
            tableName: string;
            rate: number;
            nhanXet: string;
            trangThai: number;
            createDate: Date;
            updateDate: Date;
          },
          index: number
        ) => {
          body += '<tr>';
          body += `<td class="text-center">` + (index + 1).toString() + `</td>`;
          body += `<td class="text-left">` + item.ten + `</td>`;
          body +=
            `<td class="text-left">` +
            (item.tableName === 'record'
              ? this.getTenRecord(item.modelId)
              : '') +
            `</td>`;
          body += `<td class="text-left">` + item.rate + ` sao </td>`;
          body += `<td class="text-left">` + item.nhanXet + `</td>`;
          body +=
            `<td class="text-center">` +
            (item.trangThai === 1 ? 'Hiển thị' : 'Không hiển thị') +
            `</td>`;
          body +=
            `<td class="text-right" style="text-align: right">` +
            item.createDate?.toLocaleDateString('en-GB') +
            `</td>`;
          body +=
            `<td class="text-right" style="text-align: right">` +
            item.updateDate?.toLocaleDateString('en-GB') +
            `</td>`;
          body += '</tr>';
        }
      );
    }
    body += `</tbody>
  </table>`;
    return body;
  }
  getHtml(data: any, menuname?: string) {
    return this.commonService.generalHtml(
      this.title,
      this.genTable(data, menuname),
      this.unit?.unitName ?? ''
    );
  }
  printWord(menuName?: string) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.print,
          message: LBL_DISPLAY.confirmAll,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.printAll(menuName);
        }
      });
  }
  dataToPrint(data: any, menuName?: string) {
    const html = this.getHtml(data, menuName);
    let newWin = window.open(this.title);
    newWin?.document.write(html);
    newWin?.print();
    newWin?.close();
  }
  exportPdf(menuName?: string) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.exportPdf,
          message: LBL_DISPLAY.confirmAll,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.pdfAll(menuName);
        }
      });
  }
  pdfAll(menuName?: string) {
    if (menuName) {
      this.service.selectAllBy(menuName).subscribe((ret) => {
        if (ret.code === SUCCESS_RESPONE) {
          this.dataToPdf(ret.data, menuName);
        }
      });
    } else {
      this.service.selectAll().subscribe((ret) => {
        if (ret.code === SUCCESS_RESPONE) {
          this.dataToPdf(ret.data, menuName);
        }
      });
    }
  }
  dataToPdf(data: any, menuName?: string) {
    // const htmlString = this.getHtml(data, menuName);
    // var div = document.createElement('div');
    // div.id = 'testing';
    // div.innerHTML += htmlString;
    // document.body.appendChild(div);
    //  (document.getElementById('testing') ?? new HTMLElement()).then(
    //   (canvas) => {
    //     var data = canvas.toDataURL();
    //     var docDefinition = {
    //       content: [
    //         {
    //           image: data,
    //           width: 520,
    //         },
    //       ],
    //     };
    //     pdfMake.createPdf(docDefinition).download(this.title + '.pdf');
    //     document.body.removeChild(div);
    //   }
    // );
  }

  importExcel(files: any, menuName: string) {
    if (
      files.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      files.target.files[0].type === 'application/vnd.ms-excel'
    ) {
      const targetfiles: DataTransfer = <DataTransfer>files.target;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let dataExcel: [][] = [];
        dataExcel = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let data = [];
        if (dataExcel[2]) {
          for (let i = 3; i < dataExcel.length; i++) {
            if (dataExcel[i].length > 0) {
              let item_ = new DanhGiaModel();
              const item = dataExcel[i]?.map((item1: any) => item1);
              item_.ten = item[1];
              item_.sdt = item[2];
              item_.modelId =
                menuName === 'sanpham'
                  ? this.lstSanPham.find(
                      (c: { tenSanPham: string }) =>
                        c.tenSanPham.toLocaleLowerCase() === item[3]
                    )?.sanPhamId
                  : this.lstRecord.find(
                      (c: { tieuDe: string }) =>
                        c.tieuDe.toLocaleLowerCase() === item[3]
                    )?.recordId;
              item_.rate = item[4];
              item_.nhanXet = item[5];
              if (!this.commonService.checkObjectNull(item_)) data.push(item_);
            }
          }
          this.dialog
            .open(
              SysDanhGiaCrudComponent,
              this.commonService.configDialog('80%', {
                data: data,
                action: Action.Import,
                title: this.title,
                lstSanPham: this.lstSanPham,
                lstRecord: this.lstRecord,
                menuName: menuName,
              })
            )
            .afterClosed()
            .subscribe(() => {
              this.loadData1();
            });
        }
      };
      reader.readAsBinaryString(targetfiles.files[0]);
    } else {
      this.commonService.toastrFailure('File import không đúng định dạng!');
    }
  }

  dataToExcel(data: any, menuName?: string) {
    const workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Dữ liệu', {
      pageSetup: { paperSize: Excel.PaperSize.A4 },
    });
    worksheet.columns = [
      {
        header: [this.title, '', 'STT'],
        key: 'stt',
        width: environment.with_export,
      },
      {
        header: [this.title, '', 'Tên'],
        key: 'ten',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Số điện thoại'],
        key: 'sdt',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Sản phẩm'],
        key: 'sanPham',
        width: 3 * environment.with_export,
        hidden: menuName === 'baiviet',
      },
      {
        header: [this.title, '', 'Bài viết'],
        key: 'baiViet',
        width: 3 * environment.with_export,
        hidden: menuName === 'sanpham',
      },
      {
        header: [this.title, '', 'Đánh giá'],
        key: 'rate',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Nhận xét'],
        key: 'nhanXet',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Trạng thái'],
        key: 'trangThai',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Ngày tạo'],
        key: 'createDate',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Ngày cập nhật'],
        key: 'updateDate',
        width: 3 * environment.with_export,
      },
    ];
    worksheet.mergeCells(1, 1, 1, worksheet.columns.length);
    worksheet.getCell(1, 1).style = {
      font: { bold: true, name: 'Times New Roman', size: 14 },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
    };
    data?.forEach((e: any, index: number) => {
      worksheet.addRow(
        {
          stt: index + 1,
          ten: e?.ten,
          sdt: e?.sdt,
          rate: `${e?.rate} sao`,
          baiViet:
            e.tableName === 'record' ? this.getTenRecord(e?.modelId) : '',
          nhanXet: e?.nhanXet,
          trangThai: e?.trangThai === 1 ? 'Hiển thị' : 'Không hiển thị',
          createDate: e?.createDate?.toLocaleDateString('en-GB'),
          updateDate: e?.updateDate?.toLocaleDateString('en-GB'),
        },
        'n'
      );
    });
    worksheet = this.commonService.setStyleExport(
      worksheet,
      1,
      worksheet.columns.length,
      3,
      3,
      [6, 8],
      [9, 10]
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, this.title + '.xlsx');
    });
  }
  exportExcel(menuName?: string) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.exportFile,
          message: LBL_DISPLAY.confirmAll,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.exportAll(menuName);
        }
      });
  }
  printAll(menuName?: string) {
    if (menuName) {
      this.service.selectAllBy(menuName).subscribe((ret) => {
        if (ret.code === SUCCESS_RESPONE) {
          this.dataToPrint(ret.data, menuName);
        }
      });
    } else {
      this.service.selectAll().subscribe((ret) => {
        if (ret.code === SUCCESS_RESPONE) {
          this.dataToPrint(ret.data, menuName);
        }
      });
    }
  }
  exportAll(menuName?: string) {
    if (menuName) {
      this.service.selectAllBy(menuName).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.dataToExcel(rest.data, menuName);
        }
      });
    } else {
      this.service.selectAll().subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.dataToExcel(rest.data, menuName);
        }
      });
    }
  }

  loadData1() {
    this.service.paging(this.pageModel1).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target1 = res.data ?? new DanhGiaModelListPageModelView();
      } else {
        this.commonService.toastrFailure(res.message ?? '');
      }
    });
  }

  searchPage1() {
    this.pageModel1.currentPage = 1;
    this.loadData1();
  }

  resetPage1() {
    this.pageModel1.search = '';
    this.pageModel1.searchColumn = [];
    this.pageModel1.sortColumn = [];
    this.loadData1();
  }

  create(menuName: string) {
    this.dialog
      .open(
        SysDanhGiaCrudComponent,
        this.commonService.configDialog('80%', {
          data: [new DanhGiaModel()],
          action: Action.Create,
          title: this.title,
          lstSanPham: this.lstSanPham,
          lstRecord: this.lstRecord,
          menuName: menuName,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.loadData1();
        }
      });
  }

  viewDetail(item: any, menuName: string) {
    this.dialog
      .open(
        SysDanhGiaCrudComponent,
        this.commonService.configDialog('80%', {
          data: [item],
          action: Action.Detail,
          title: this.title,
          lstSanPham: this.lstSanPham,
          lstRecord: this.lstRecord,
          menuName: menuName,
        })
      )
      .afterClosed()
      .subscribe(() => {});
  }

  viewUpdate(item: any, menuName: string) {
    this.dialog
      .open(
        SysDanhGiaCrudComponent,
        this.commonService.configDialog('80%', {
          data: [item],
          action: Action.Update,
          title: this.title,
          lstSanPham: this.lstSanPham,
          lstRecord: this.lstRecord,
          menuName: menuName,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.loadData1();
        }
      });
  }
  viewDelete(_item: any) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.delete,
          message: LBL_DISPLAY.confirmDelete,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.service.delete(_item.danhGiaId).subscribe((rest) => {
            if (rest.code === SUCCESS_RESPONE) {
              this.commonService.toastrSuccess(rest.message);
              this.loadData1();
            } else {
              this.commonService.toastrFailure(rest.message);
            }
          });
        }
      });
  }

  changeStatus(item: any) {
    if (item.trangThai) item.trangThai = 1;
    else item.trangThai = 0;
    this.service.updateTrangThai(item).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess(rest.message);
      } else {
        this.commonService.toastrFailure(rest.message);
      }
    });
  }
}
