import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Int32ApiResponse,
  PhongBanModel,
  PhongBanModelListApiResponse,
  PhongBanModelListPageModelViewApiResponse,
  QTHTApiService,
  ResultImportModelApiResponse,
  SysPageModel,
} from 'src/app/core/services/qtht.service';
@Injectable({
  providedIn: 'root',
})
export class SysPhongBanService {
  constructor(private api: QTHTApiService) {}

  paging(
    body: SysPageModel
  ): Observable<PhongBanModelListPageModelViewApiResponse> {
    return this.api.appQthtApiPhongBanPaging(body);
  }
  getUnitInfo() {
    return this.api.appQthtApiSysUnitExtandGetUnitInfo();
  }
  selectAll(): Observable<PhongBanModelListApiResponse> {
    return this.api.appQthtApiPhongBanSelectAll();
  }
  import(
    body: PhongBanModel[] | undefined
  ): Observable<ResultImportModelApiResponse> {
    return this.api.appQthtApiPhongBanImport(body);
  }
  delete(phongBanId: number): Observable<Int32ApiResponse> {
    return this.api.appQthtApiPhongBanDelete(phongBanId);
  }
  update(body: PhongBanModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiPhongBanUpdate(body);
  }
  updateTrangThai(
    body: PhongBanModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appQthtApiPhongBanUpdateTrangThai(body);
  }
  create(body: PhongBanModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiPhongBanCreate(body);
  }
}
