import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { QuillModule } from 'ngx-quill';
import { SysRecordCrudComponent } from './sysRecord-crud/sysRecord-crud.component';
import { SysRecordIndexComponent } from './sysRecord-index/sysRecord-index.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [SysRecordIndexComponent, SysRecordCrudComponent],
  imports: [
    FormsModule,
    ThemeModule,
    MatSelectModule,
    MatSlideToggleModule,
    QuillModule.forRoot(),
    NgxSpinnerModule,
    NgSelectModule,
  ],
})
export class SysRecordModule {}
