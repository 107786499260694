import { Component, ViewEncapsulation, Input, OnInit } from '@angular/core';
import { LBL_DISPLAY } from 'src/app/core/customs/constants';

@Component({
  selector: 'ngx-btn-phone',
  templateUrl: './btn-phone.component.html',
  styleUrls: ['./btn-phone.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class BtnPhoneComponent implements OnInit {
  @Input() text: string | undefined;
  @Input() color: string | undefined;
  @Input() disabled: boolean | undefined;
  @Input() icon: string | undefined;
  constructor() {}

  ngOnInit(): void {
    this.text = this.text ?? LBL_DISPLAY.phone;
    this.disabled = this.disabled ?? false;
    this.color = this.color ?? 'primary';
  }
}
