import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Int32ApiResponse,
  UserModel,
  QTHTApiService,
  SysPageModel,
  UserModelListPageModelViewApiResponse,
  MenuModelListApiResponse,
  RoleModelListApiResponse,
  RoleModelListModelImport,
  UserModelListApiResponse,
  ResultImportModelApiResponse,
  UserModelApiResponse,
  StringApiResponse,
} from 'src/app/core/services/qtht.service';
@Injectable({
  providedIn: 'root',
})
export class SysUserService {
  constructor(private api: QTHTApiService) {}

  paging(
    body: SysPageModel
  ): Observable<UserModelListPageModelViewApiResponse> {
    return this.api.appQthtApiSysUserPaging(body);
  }
  getUnitInfo() {
    return this.api.appQthtApiSysUnitExtandGetUnitInfo();
  }
  insert(body: UserModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiSysUserCreate(body);
  }
  update(body: UserModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiSysUserUpdate(body);
  }
  updateTrangThai(body: UserModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiSysUserUpdateTrangThai(body);
  }
  delete(userId: number): Observable<Int32ApiResponse> {
    return this.api.appQthtApiSysUserDelete(userId);
  }
  selectOne(userId: number): Observable<UserModelApiResponse> {
    return this.api.appQthtApiSysUserSelectOne(userId);
  }
  import(
    body: UserModel[] | undefined
  ): Observable<ResultImportModelApiResponse> {
    return this.api.appQthtApiSysUserImport(body);
  }
  selectAll(): Observable<UserModelListApiResponse> {
    return this.api.appQthtApiSysUserSelectAll();
  }

  getMenuByUnit(): Observable<MenuModelListApiResponse> {
    return this.api.appQthtApiMenuExtandGetModelByUnit();
  }
  GetModelByUserId(userId: number): Observable<RoleModelListApiResponse> {
    return this.api.appQthtApiSysUserMenuExtandGetModelByUserId(userId);
  }
  addRole(
    body: RoleModelListModelImport | undefined
  ): Observable<ResultImportModelApiResponse> {
    return this.api.appQthtApiSysUserMenuImport(body);
  }
  pagingExtand(
    body: SysPageModel | undefined
  ): Observable<UserModelListApiResponse> {
    return this.api.appQthtApiSysUserExtandPagingBase(body);
  }
  resetPassword(body: UserModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiSysUserResetPassword(body);
  }
  getImage(userId: number): Observable<StringApiResponse> {
    return this.api.appQthtApiSysUserExtandGetImage(userId);
  }
  selectAllBase(): Observable<UserModelListApiResponse> {
    return this.api.appQthtApiSysUserExtandSelectAllBase();
  }
}
