import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

import { Router } from '@angular/router';
import {
  QTHTApiService,
  UserModelBasic,
  UserModelRepones,
} from './qtht.service';
import { TokenService } from '../token.service';
import { CommonService } from '../customs/common.service';
import { JwtHelperService } from '@auth0/angular-jwt';
import { SUCCESS_RESPONE } from '../customs/constants';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  addMinutes(minutes: any) {
    return new Date(new Date().getTime() + minutes * 60000);
  }

  private jwtHelper: JwtHelperService;
  private token?: string = '';
  private rfToken?: string = '';
  private userId?: number = 0;
  private unitId?: number = 0;
  constructor(
    private tokenService: TokenService,
    private commonService: CommonService,
    private qthtService: QTHTApiService,
    private router: Router
  ) {
    this.jwtHelper = new JwtHelperService();
    this.jwtHelper.tokenGetter = () => this.tokenService.getToken() ?? '';
    this.loadSavedAuthData();
  }
  getTime(t1: Date | any) {
    if (t1) {
      var t2 = new Date();
      return t1.getTime() - t2.getTime();
    }
    return 0;
  }
  refresh() {
    alert('Phiên làm việc đã hết hạn. Vui lòng đăng nhập lại');
    this.router.navigate(['/login']);
  }
  saveToken(res: any) {
    this.tokenService.saveToken(res?.data?.tokenModel);
    this.token = res?.data?.tokenModel?.token ?? '';
    this.rfToken = res?.data?.tokenModel?.refreshToken ?? '';
    this.userId = res?.data?.userModel?.userId;
    this.unitId = res?.data?.unitModel?.unitId;
    this.tokenService.setUserReponse(
      res?.data ?? new UserModelRepones(),
      this.jwtHelper.getTokenExpirationDate()
    );
  }
  saveTokenSSO(token: any) {
    this.tokenService.saveTokenSSO(token);
  }
  setReloadPage() {
    var timeD = this.getTime(
      this.jwtHelper.getTokenExpirationDate() ?? new Date()
    );
    setInterval(
      () => {
        this.refreshToken();
      },
      timeD > 0 ? timeD - environment.TimeWaiting : 0
    );
  }

  login(userLogin: UserModelBasic): Observable<any> {
    return this.qthtService.appAuthApiAuthAuthenticate(userLogin).pipe(
      tap((res) => {
        if (res.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(res.message);
          if (
            window.location.origin === res?.data?.userInfo?.website ||
            !environment.production
          ) {
            this.saveToken(res);
            this.setReloadPage();
            this.router.navigate([`/qtht`]).then(
              (nav) => {},
              (err) => {
                this.logout();
              }
            );
          } else {
            this.commonService.toastrWarning(
              'Hệ thống đang chuyển hướng sang đơn vị của bạn'
            );
            window.location.href = `${res?.data?.userInfo?.website}/login/${
              res?.data?.tokenModel?.refreshToken ?? ''
            }`;
          }
        } else {
          this.commonService.toastrFailure(res.message ?? '');
        }
      })
    );
  }
  private loadSavedAuthData(): void {
    this.token = this.getSavedAuthData() ?? '';
  }

  private getSavedAuthData() {
    return this.tokenService.getToken();
  }
  async refreshTokenSSO(rfToken: string): Promise<any> {
    return this.qthtService
      .appAuthApiAuthRefreshToken(rfToken)
      .subscribe((res) => {
        if (res.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(res.message);
          if (window.location.origin === res?.data?.userInfo?.website) {
            this.saveToken(res);
            this.setReloadPage();
            this.router.navigate([`/qtht`]).then(
              (nav) => {},
              (err) => {
                this.logout();
              }
            );
          } else {
            this.commonService.toastrWarning(
              'Hệ thống đang chuyển hướng sang đơn vị của bạn'
            );
            window.location.href = `${res?.data?.userInfo?.website}/login/${
              res?.data?.tokenModel?.refreshToken ?? ''
            }`;
          }
        } else {
          this.router.navigate(['/login']);
        }
      });
  }
  async refreshToken(): Promise<any> {
    if (this.token && this.token.length > 0) {
      return this.qthtService
        .appAuthApiAuthRefreshToken(this.rfToken)
        .subscribe((res) => {
          if (res.code === SUCCESS_RESPONE) {
            if (window.location.origin === res?.data?.userInfo?.website) {
              this.saveToken(res);
              this.setReloadPage();
              this.router.navigate([`/qtht`]).then(
                (nav) => {},
                (err) => {
                  this.logout();
                }
              );
            } else {
              this.commonService.toastrWarning(
                'Hệ thống đang chuyển hướng sang đơn vị của bạn'
              );
              window.location.href = `${res?.data?.userInfo?.website}/login/${
                res?.data?.tokenModel?.refreshToken ?? ''
              }`;
            }
          } else {
            this.router.navigate(['/login']);
          }
        });
    } else {
      this.router.navigate(['/login']);
    }
  }
  refreshLocal() {
    this.token = '';
    this.unitId = 0;
    this.userId = 0;
    this.tokenService.removeToken();
    this.tokenService.removeCookie();
  }
  logout(): void {
    this.refreshLocal();
    this.router.navigate(['/login']);
  }

  checkTokenExpired(): boolean {
    if (this.jwtHelper.isTokenExpired()) return true;
    return false;
  }
}
