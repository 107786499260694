import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SysUserService } from '../sysUser.service';
import { Inject } from '@angular/core';
import { Action, SUCCESS_RESPONE } from 'src/app/core/customs/constants';
@Component({
  selector: 'ngx-sysUser-crud',
  templateUrl: './sysUser-crud.component.html',
  styleUrls: ['./sysUser-crud.component.scss'],
})
export class SysUserCrudComponent implements OnInit {
  constructor(
    private service: SysUserService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: [];
      tuDien: [];
      action: string;
      title: string;
      lstTrangThai: [];
    },
    public dialogRef: MatDialogRef<SysUserCrudComponent>
  ) {}
  target: any = {};
  isImport() {
    return this.target.action === Action.Import;
  }
  isUpdate() {
    return this.target.action === Action.Update;
  }
  ngOnInit(): void {
    this.target = this.data;
    if (
      this.target.action === Action.Detail ||
      this.target.action === Action.Update
    ) {
      this.service.selectOne(this.target.data[0].userId).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.target.data[0] = rest.data;
          this.getImage(this.target.data[0]);
        }
      });
    }
  }
  getImage(item: any) {
    item.image = '../../assets/images/profile.webp';
    this.service.getImage(item.userId).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        item.image =
          rest.data === '' ? '../../assets/images/profile.webp' : rest.data;
      }
    });
  }

  getTitle() {
    return (this.target.action + this.target.title).toUpperCase();
  }
  update(frm: any) {
    if (this.target.action === Action.Create) {
      this.target.data[0].trangThai = 1;
      this.service.insert(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Update) {
      this.service.update(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Import) {
      this.service.import(this.target.data).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    }
  }
  isDetail() {
    return this.target.action === Action.Detail;
  }
}
