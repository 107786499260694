import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-reset-pass',
  templateUrl: './icon-reset-pass.component.html',
  styleUrls: ['./icon-reset-pass.component.scss'],
})
export class IconResetPassComponent {
  constructor() {}
}
