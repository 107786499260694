import { Component, Input, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Action,
  LBL_DISPLAY,
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from 'src/app/core/customs/constants';
import {
  ChucDanhModelListPageModelView,
  PhongBanModel,
  SysPageModel,
} from 'src/app/core/services/qtht.service';
import { DialogConfirmComponent } from 'src/app/theme/components/template/button/dialog/dialog-confirm/dialog-confirm.component';
import { SysChucDanhCrudComponent } from '../sysChucDanh-crud/sysChucDanh-crud.component';
import { SysChucDanhService } from '../sysChucDanh.service';

@Component({
  selector: 'ngx-sysChucDanh-detail',
  templateUrl: './sysChucDanh-detail.component.html',
  styleUrls: ['./sysChucDanh-detail.component.scss'],
})
export class SysChucDanhDetailComponent implements OnInit {
  constructor(
    private service: SysChucDanhService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}
  title = 'Quản lý chức danh';
  target: any = {
    data: [],
  };
  pageModel: SysPageModel = new SysPageModel({
    search: '',
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: '',
    sortColumn: [],
    searchColumn: [],
  });
  @Input() lstPhongBan: PhongBanModel[] | undefined;
  @Input() phongBan: PhongBanModel | undefined;
  @Input() hiden: boolean = false;
  ngOnInit(): void {
    this.pageModel.condition = this.phongBan?.phongBanId?.toString();
  }
  getSTT(index: number) {
    return (
      (this.pageModel.pageSize ?? 0) * ((this.pageModel.currentPage ?? 1) - 1) +
      index +
      1
    );
  }

  loadData() {
    this.service.paging(this.pageModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data ?? new ChucDanhModelListPageModelView();
      } else {
        this.commonService.toastrFailure(res.message ?? '');
      }
    });
  }
  searchPage() {
    this.pageModel.currentPage = 1;
    this.loadData();
  }

  resetPage() {
    this.pageModel.search = '';
    this.pageModel.searchColumn = [];
    this.pageModel.sortColumn = [];
    this.loadData();
  }
  viewDetail(item: any) {
    this.dialog
      .open(
        SysChucDanhCrudComponent,
        this.commonService.configDialog('80%', {
          data: [item],
          action: Action.Detail,
          title: this.title,
          lstPhongBan: this.lstPhongBan,
        })
      )
      .afterClosed()
      .subscribe(() => {});
  }

  viewUpdate(item: any) {
    this.dialog
      .open(
        SysChucDanhCrudComponent,
        this.commonService.configDialog('80%', {
          data: [item],
          action: Action.Update,
          title: this.title,
          lstPhongBan: this.lstPhongBan,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) this.loadData();
      });
  }

  viewDelete(_item: any) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.delete,
          message: LBL_DISPLAY.confirmDelete,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.service.delete(_item.chucDanhId).subscribe((rest) => {
            if (rest.code === SUCCESS_RESPONE) {
              this.commonService.toastrSuccess(rest.message);
              this.loadData();
            } else {
              this.commonService.toastrFailure(rest.message);
            }
          });
        }
      });
  }

  changeSee() {
    this.hiden = !this.hiden;
    if (!this.target.totalPage && this.hiden) this.loadData();
  }
  changeStatus(item: any) {
    if (item.trangThai) item.trangThai = 1;
    else item.trangThai = 0;
    this.service.updateTrangThai(item).subscribe((result) => {
      if (result.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess(result.message);
      } else {
        this.commonService.toastrFailure(result.message);
      }
    });
  }
}
