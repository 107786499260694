import { RouterModule, Routes } from '@angular/router';
import { MenuComponent } from '../theme/pages/menu/menu.component';
import { NgModule } from '@angular/core';
import { routes as SysUserRouter } from './sysUser/sysUser-routing.component';
import { routes as SysRecordRouter } from './sysRecord/sysRecord-routing.component';
import { routes as SysPhongBanRouter } from './sysPhongBan/sysPhongBan-routing.component';
import { routes as SysChucDanhRouter } from './sysChucDanh/sysChucDanh-routing.component';
import { routes as SysRolesRouter } from './sysRoles/sysRoles-routing.component';
import { routes as SysLoaiSanPhamRouter } from './sysLoaiSanPham/sysLoaiSanPham-routing.component';
import { routes as SysDanhGiaRouter } from './sysDanhGia/sysDanhGia-routing.component';
export const routes: Routes = [
  {
    path: '',
    component: MenuComponent,
    children: [
      { path: 'sysuser', children: SysUserRouter },
      { path: 'sysroleuser', children: SysRolesRouter },
      { path: 'sysphongban', children: SysPhongBanRouter },
      { path: 'syschucdanh', children: SysChucDanhRouter },
      { path: 'sysrecord', children: SysRecordRouter },
      { path: 'sysloaisanpham', children: SysLoaiSanPhamRouter },
      { path: 'sysdanhgia', children: SysDanhGiaRouter },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class QTHTRoutingModule {}
