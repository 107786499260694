import { Component, Input, OnInit } from '@angular/core';
import { LBL_DISPLAY } from 'src/app/core/customs/constants';
class Guid {
  static newGuid() {
    return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(
      /[xy]/g,
      function (c) {
        const r = (Math.random() * 16) | 0,
          v = c === 'x' ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
}
@Component({
  selector: 'ngx-btn-import-file',
  templateUrl: './btn-import-file.component.html',
  styleUrls: ['./btn-import-file.component.scss'],
})
export class BtnImportFileComponent implements OnInit {
  @Input() disabled: boolean | undefined;
  @Input() color: string | undefined;
  @Input() text: string | undefined;
  @Input() multiple: boolean | undefined;
  idFile: string = Guid.newGuid();
  constructor() {}

  ngOnInit(): void {
    this.text = this.text ?? LBL_DISPLAY.importFile;
    this.disabled = this.disabled ?? false;
    this.color = this.color ?? 'success';
  }
}
