import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';

import { AuthGuard } from './core/guards/auth.guard';
import { Logged } from './core/guards/logged.guard';
import { LoginLayoutComponent } from './theme/pages/login-layout/login-layout.component';

const appRoutes: Routes = [
  {
    path: '',
    loadChildren: () => import('./home/home.module').then((m) => m.HomeModule),
  },
  {
    path: 'login',
    component: LoginLayoutComponent,
  },
  {
    path: 'login/:token',
    component: LoginLayoutComponent,
  },
  {
    path: 'qtht',
    loadChildren: () => import('./qtht/qtht.module').then((m) => m.QTHTModule),
  },

  {
    path: '**',
    redirectTo: '',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(appRoutes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
  providers: [AuthGuard, Logged],
})
export class AppRoutingModule {}
