import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import {
  NbSidebarService,
  NbMenuService,
  NbThemeService,
  NbMediaBreakpointsService,
} from '@nebular/theme';
import { CommonService } from 'src/app/core/customs/common.service';
import { AuthService } from 'src/app/core/services/auth.service';
import { TokenService } from 'src/app/core/token.service';
import { ChangePasswordComponent } from '../../pages/change-password/change-password.component';
import { UserInfoComponent } from '../../pages/user-info/user-info.component';
import { QTHTApiService } from 'src/app/core/services/qtht.service';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { map } from 'rxjs';

@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit {
  userPictureOnly: boolean = false;
  user: any;
  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [
    { title: 'Thông tin tài khoản' },
    { title: 'Đổi mật khẩu' },
    { title: 'Đăng xuất' },
  ];

  constructor(
    private sidebarService: NbSidebarService,
    private themeService: NbThemeService,
    private tokenService: TokenService,
    private breakpointService: NbMediaBreakpointsService,
    private authService: AuthService,
    private router: Router,
    private dialog: MatDialog,
    private commonService: CommonService,
    private qthtService: QTHTApiService
  ) {}

  ngOnInit() {
    this.currentTheme = this.themeService.currentTheme;
    if (this.user === null || this.user === undefined)
      this.tokenService.getUserInfo().subscribe((users: any) => {
        this.user = users;
        if (users === null || users === undefined) {
          this.dangXuat();
        } else {
          this.loadData();
        }
      });
    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService
      .onMediaQueryChange()
      .pipe(map(([, currentBreakpoint]) => currentBreakpoint.width < xl))
      .subscribe(
        (isLessThanXl: boolean) => (this.userPictureOnly = isLessThanXl)
      );

    this.themeService
      .onThemeChange()
      .pipe(map(({ name }) => name))
      .subscribe((themeName) => (this.currentTheme = themeName));
  }
  getImage() {
    this.user.image = '../../assets/images/profile.webp';
    this.qthtService
      .appQthtApiSysUserExtandGetImage(this.user.userId)
      .subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          if (rest.data) this.user.image = rest.data;
        }
      });
    return this.user.image;
  }
  loadData() {
    this.qthtService.appQthtApiSysUserExtandGetUserInfo().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.user = rest.data;
        this.getImage();
      }
    });
  }
  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');

    return false;
  }

  selectToolbar() {
    const menuService = new NbMenuService();
    let status = true;
    menuService.onItemClick().subscribe((menu) => {
      switch (menu.item.title) {
        case 'Đăng xuất':
          if (status) {
            this.authService.logout();
            this.router.navigate(['/login']);
            status = false;
          }
          break;
        case 'Thông tin tài khoản':
          if (status) {
            this.dialog
              .open(
                UserInfoComponent,
                this.commonService.configDialog('40%', {
                  data: { ...this.user },
                })
              )
              .afterClosed()
              .subscribe((rest) => {
                if (rest) {
                  this.loadData();
                }
              });
            status = false;
          }
          break;
        case 'Đổi mật khẩu':
          if (status) {
            this.dialog
              .open(
                ChangePasswordComponent,
                this.commonService.configDialog('50%', {})
              )
              .afterClosed()
              .subscribe((rest) => {
                if (rest) {
                  this.authService.logout();
                }
              });
            status = false;
          }
          break;
      }
    });
  }
  dangXuat() {
    let status = true;
    if (status) {
      this.authService.logout();
      status = false;
    }
  }
}
