import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Action,
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from 'src/app/core/customs/constants';
import { SysPageModel } from 'src/app/core/services/qtht.service';
import { SysRoleCrudComponent } from '../sysRole-crud/sysRole-crud.component';
import { SysUserService } from '../../sysUser/sysUser.service';

@Component({
  selector: 'ngx-sysRoles-index',
  templateUrl: './sysRoles-index.component.html',
  styleUrls: ['./sysRoles-index.component.scss'],
})
export class SysRolesIndexComponent implements OnInit {
  constructor(
    private service: SysUserService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}
  title = 'Phân quyền tài khoản';
  target: any = {
    data: [],
  };

  pageModel: SysPageModel = new SysPageModel({
    search: '',
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: '',
    sortColumn: [],
    searchColumn: [],
  });

  ngOnInit(): void {
    this.loadData();
    this.service.getMenuByUnit().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.lstMenu = rest.data ?? [];
      }
    });
  }
  lstMenu: any[] = [];
  getSTT(index: number) {
    return (
      (this.pageModel.pageSize ?? 0) * ((this.pageModel.currentPage ?? 1) - 1) +
      index +
      1
    );
  }
  loadData() {
    this.service.pagingExtand(this.pageModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res;
      } else {
        this.commonService.toastrFailure(res.message ?? '');
      }
    });
  }

  searchPage() {
    this.pageModel.currentPage = 1;
    this.loadData();
  }

  resetPage() {
    this.pageModel.search = '';
    this.pageModel.searchColumn = [];
    this.pageModel.sortColumn = [];
    this.loadData();
  }

  changerole(item: any) {
    this.dialog
      .open(
        SysRoleCrudComponent,
        this.commonService.configDialog('80%', {
          data: item,
          action: Action.Role,
          title: this.title,
          lstMenu: this.lstMenu,
        })
      )
      .afterClosed()
      .subscribe(() => {});
  }
}
