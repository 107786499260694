<nav class="navbar navbar-expand-lg navbar-light bg-light">
  <div class="container">
    <img
      src="../../../../assets/images/logo.png"
      loading="lazy"
      alt="Logo Vận chuyển Việt Nam"
      title="Logo Vận chuyển Việt Nam"
      height="80"
      width="80"
    />

    <h1 title="Vận chuyển Việt Nam" style="color: #995213">
      Vận chuyển Việt Nam
    </h1>
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarSupportedContent"
      aria-controls="navbarSupportedContent"
      aria-expanded="false"
      aria-label="Toggle navigation"
      style="background-color: aliceblue"
    >
      <span class="navbar-toggler-icon"></span>
    </button>

    <div class="collapse navbar-collapse" id="navbarSupportedContent">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            href="/trang-chu"
            title="Trang chủ Vận chuyển Việt Nam"
            >TRANG CHỦ</a
          >
        </li>
        <li class="nav-item dropdown">
          <a
            class="nav-link dropdown-toggle"
            id="navbarDropdown"
            role="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
            href="/dich-vu"
            title="Dịch vụ của Vận chuyển Việt Nam"
            rou
          >
            DỊCH VỤ
          </a>
          <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
            <li *ngFor="let item of lstDichVu">
              <a class="dropdown-item" href="/dich-vu/{{ item.path }}">{{
                item.tenLoaiSanPham
              }}</a>
            </li>
          </ul>
        </li>
        <li class="nav-item">
          <a
            class="nav-link"
            aria-current="page"
            routerLink="/tin-tuc"
            routerLinkActive="active"
            href="/tin-tuc"
            title="Tin tức mới nhất của Vận chuyển Việt Nam"
            >TIN TỨC</a
          >
        </li>
      </ul>
    </div>
  </div>
</nav>

<div class="row input-group" style="top: 0">
  <a
    href="tel:0339919564"
    class="hotlinemp all"
    style="max-width: 100px"
    title="Hotline Vận chuyển Việt Nam"
  >
    <div class="mypage-alo-phone">
      <div class="animated infinite zoomIn mypage-alo-ph-circle"></div>
      <div class="animated infinite pulse mypage-alo-ph-circle-fill"></div>
      <div class="animated infinite tada mypage-alo-ph-img-circle"></div>
      <span>0339919564</span>
    </div>
  </a>
</div>
