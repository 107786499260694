<ng-container *ngIf="multiple; else elseTemplate">
  <input
    type="file"
    id="{{ idFile }}"
    accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    hidden
    multiple
  />
  <label class="btn btn-{{ color }}" for="{{ idFile }}">{{ text }}</label>
</ng-container>
<ng-template #elseTemplate>
  <input
    type="file"
    id="{{ idFile }}"
    accept=".xlsx, .xls, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    hidden
  />
  <label class="btn btn-{{ color }}" for="{{ idFile }}"
    ><a><i class="fas fa-file-import fa-sm"></i>&nbsp;</a>{{ text }}</label
  >
</ng-template>
