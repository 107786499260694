import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Int32ApiResponse,
  ChucDanhModel,
  ChucDanhModelListPageModelViewApiResponse,
  QTHTApiService,
  SysPageModel,
  ChucDanhModelListApiResponse,
  PhongBanModelListApiResponse,
  ChucDanhModelApiResponse,
  ResultImportModelApiResponse,
} from 'src/app/core/services/qtht.service';
@Injectable({
  providedIn: 'root',
})
export class SysChucDanhService {
  constructor(private api: QTHTApiService) {}
  getUnitInfo() {
    return this.api.appQthtApiSysUnitExtandGetUnitInfo();
  }
  paging(
    body: SysPageModel
  ): Observable<ChucDanhModelListPageModelViewApiResponse> {
    return this.api.appQthtApiChucDanhPaging(body);
  }
  selectAll(): Observable<ChucDanhModelListApiResponse> {
    return this.api.appQthtApiChucDanhSelectAll();
  }
  selectOne(chucdanhId: number): Observable<ChucDanhModelApiResponse> {
    return this.api.appQthtApiChucDanhSelectOne(chucdanhId);
  }
  import(
    body: ChucDanhModel[] | undefined
  ): Observable<ResultImportModelApiResponse> {
    return this.api.appQthtApiChucDanhImport(body);
  }
  delete(chucDanhId: number): Observable<Int32ApiResponse> {
    return this.api.appQthtApiChucDanhDelete(chucDanhId);
  }
  update(body: ChucDanhModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiChucDanhUpdate(body);
  }
  updateTrangThai(
    body: ChucDanhModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appQthtApiChucDanhUpdateTrangThai(body);
  }
  create(body: ChucDanhModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appQthtApiChucDanhCreate(body);
  }
  padingBase(
    body: SysPageModel | undefined
  ): Observable<PhongBanModelListApiResponse> {
    return this.api.appQthtApiSysPhongBanExtandPagingBase(body);
  }
  selectAllBasePhongBan(): Observable<PhongBanModelListApiResponse> {
    return this.api.appQthtApiSysPhongBanExtandSelectAllBase();
  }
}
