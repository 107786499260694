import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  LoaiSanPhamModelListApiResponse,
  VCVNApiService,
  RecordModel,
  RecordModelApiResponse,
  RecordModelListApiResponse,
  RecordModelListPageModelViewApiResponse,
} from 'src/app/core/services/vcvn.service';
import {
  Int32ApiResponse,
  QTHTApiService,
  StringApiResponse,
  SysPageModel,
} from 'src/app/core/services/qtht.service';

@Injectable({
  providedIn: 'root',
})
export class SysRecordService {
  constructor(private api: VCVNApiService, private apiQtht: QTHTApiService) {}

  paging(
    body: SysPageModel
  ): Observable<RecordModelListPageModelViewApiResponse> {
    return this.api.appVCVNApiRecordPaging(body);
  }
  getUnitInfo() {
    return this.apiQtht.appQthtApiSysUnitExtandGetUnitInfo();
  }
  selectAll(): Observable<RecordModelListApiResponse> {
    return this.api.appVCVNApiRecordSelectAll();
  }
  delete(recordId: number): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiRecordDelete(recordId);
  }
  update(body: RecordModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiRecordUpdate(body);
  }
  updateTrangThai(body: RecordModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiRecordUpdateTrangThai(body);
  }
  insert(body: RecordModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiRecordCreate(body);
  }
  selectOnce(recordId: number): Observable<RecordModelApiResponse> {
    return this.api.appVCVNApiRecordSelectOne(recordId);
  }
  selectAllLoaiSanPham(): Observable<LoaiSanPhamModelListApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamExtandSelectAllBase();
  }
  getImage(recordId: number): Observable<StringApiResponse> {
    return this.api.appVCVNApiRecordExtandGetImage(recordId);
  }
  getNoiDung(recordId: number): Observable<StringApiResponse> {
    return this.api.appVCVNApiRecordExtandGetNoiDung(recordId);
  }
}
