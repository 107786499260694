<form #frm="ngForm" (ngSubmit)="changePassword(frm)">
  <div class="card text-center">
    <div
      class="card-header"
      style="background-color: rgb(183, 250, 50); font-size: large"
    >
      <div class="text-center">Đổi mật khẩu</div>
    </div>
    <div class="card-body">
      <div class="form-group">
        <div class="row">
          <div class="col-4 align-self-center text-left">
            <label for="userName" class="required">Tên đăng nhập</label>
          </div>
          <div class="col">
            <input
              autocomplete="off"
              type="text"
              class="form-control"
              [(ngModel)]="target.userName"
              name="userName"
              maxlength="50"
              #userName="ngModel"
              id="userName"
              placeholder="Nhập lại tên đăng nhập"
              required
            />
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-4 align-self-center text-left">
            <label for="curentPassword" class="required"
              >Mật khẩu hiện tại</label
            >
          </div>
          <div class="col">
            <input
              [type]="hideOrShowOldPass ? 'text' : 'password'"
              autocomplete="password"
              class="form-control"
              [(ngModel)]="target.curentPassword"
              name="curentPassword"
              maxlength="50"
              #curentPassword="ngModel"
              id="curentPassword"
              placeholder="Mật khẩu hiện tại"
              required
            />
          </div>
          <div class="col-1 align-self-center">
            <span>
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye': hideOrShowOldPass,
                  'fa-eye-slash': !hideOrShowOldPass
                }"
                aria-hidden="true"
                (click)="hideOrShowOldPass = !hideOrShowOldPass"
              ></i>
            </span>
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-4 align-self-center text-left">
            <label for="newPassword" class="required">Mật khẩu mới</label>
          </div>
          <div class="col">
            <input
              autocomplete="new-password"
              [type]="hideOrShowNewPass ? 'text' : 'password'"
              class="form-control"
              [(ngModel)]="target.newPassword"
              name="newPassword"
              maxlength="50"
              #newPassword="ngModel"
              id="newPassword"
              placeholder="Mật khẩu phải có chữ, số, ký tự đặc biệt và lớn hơn 8 ký tự"
              pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*(\W|_)).{5,}$"
              required
            />
          </div>
          <div class="col-1 align-self-center">
            <span>
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye': hideOrShowNewPass,
                  'fa-eye-slash': !hideOrShowNewPass
                }"
                aria-hidden="true"
                (click)="hideOrShowNewPass = !hideOrShowNewPass"
              ></i>
            </span>
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-4 align-self-center text-left">
            <label for="password2" class="required"
              >Nhập lại mật khẩu mới</label
            >
          </div>
          <div class="col">
            <input
              [type]="hideOrShowNewPassAgain ? 'text' : 'password'"
              autocomplete="new-password"
              class="form-control"
              [(ngModel)]="password2"
              name="password2"
              maxlength="50"
              #newPassword="ngModel"
              id="password2"
              placeholder="Nhập lại mật khẩu mới"
              required
            />
          </div>
          <div class="col-1 align-self-center">
            <span>
              <i
                class="fa"
                [ngClass]="{
                  'fa-eye': hideOrShowNewPassAgain,
                  'fa-eye-slash': !hideOrShowNewPassAgain
                }"
                aria-hidden="true"
                (click)="hideOrShowNewPassAgain = !hideOrShowNewPassAgain"
              ></i>
            </span>
          </div>
        </div>
        &nbsp;
      </div>
      &nbsp;
    </div>
    <div class="card-footer text-center">
      <div class="row justify-content-end">
        <div class="col text-right">
          <ngx-btn-save
            [disabled]="frm.invalid === true"
            [text]="'Đổi mật khẩu'"
            (click)="changePassword(frm)"
          ></ngx-btn-save>
          &nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</form>
