import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SysDanhGiaIndexComponent } from './sysDanhGia-index/sysDanhGia-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: SysDanhGiaIndexComponent }],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SysDanhGiaRoutingModule {}
