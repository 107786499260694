import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/customs/common.service';
import {
  ChangePasswordModel,
  QTHTApiService,
} from 'src/app/core/services/qtht.service';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss'],
})
export class ChangePasswordComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<ChangePasswordComponent>,
    private commonService: CommonService,
    private qthtService: QTHTApiService
  ) {}

  target = new ChangePasswordModel();
  hideOrShowOldPass: boolean = false;
  hideOrShowNewPass: boolean = false;
  hideOrShowNewPassAgain: boolean = false;
  password2: string = '';
  checkPassWord() {
    return (
      this.password2 === this.target.newPassword &&
      this.target.curentPassword !== this.target.newPassword
    );
  }

  ngOnInit(): void {
    this.commonService.getUserInfo().subscribe((rest) => {
      if (rest) {
        this.target.userId = rest.userId;
      }
    });
  }

  changePassword(frm: any) {
    if (this.password2 !== this.target.newPassword) {
      this.commonService.toastrWarning('Nhập lại mật khẩu mới chưa đúng.');
      return;
    }
    if (this.target.curentPassword === this.target.newPassword) {
      this.commonService.toastrWarning(
        'Mật khẩu cũ và mật khẩu mới đang giống nhau.'
      );
      return;
    }
    this.qthtService
      .appQthtApiSysUserExtandChangePassword(this.target)
      .subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
  }
}
