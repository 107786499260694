import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { SysUserIndexComponent } from './sysUser-index/sysUser-index.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SysUserCrudComponent } from './sysUser-crud/sysUser-crud.component';
import { SysRoleCrudComponent } from '../sysRoles/sysRole-crud/sysRole-crud.component';
import { SysRolesIndexComponent } from '../sysRoles/sysRoles-index/sysRoles-index.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    SysUserIndexComponent,
    SysUserCrudComponent,
    SysRolesIndexComponent,
    SysRoleCrudComponent,
  ],
  imports: [FormsModule, ThemeModule, MatSlideToggleModule, NgxSpinnerModule],
})
export class SysUserModule {}
