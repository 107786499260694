<ngx-spinner size="large" type="ball-scale-multiple"> </ngx-spinner>
<form #frm="ngForm" (ngSubmit)="update(frm)">
  <nb-card>
    <nb-card-header>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          <p>
            <span>{{ getTitle() }}</span>
          </p>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row" style="padding-top: 20px">
        <div
          class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
          style="max-height: 300px; overflow: auto"
        >
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center">STT</th>
                <th class="text-center">Tên phòng ban</th>
                <th class="text-center" *ngIf="isDetail() || isUpdate()">
                  Ngày tạo
                </th>
                <th class="text-center" *ngIf="isDetail()">Ngày cập nhật</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of target.data; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td class="text-center">
                  <input
                    autocomplete="false"
                    type="text"
                    class="form-control"
                    name="item.tenPhongBan{{ i }}"
                    maxlength="50"
                    [(ngModel)]="item.tenPhongBan"
                    required
                    [disabled]="isDetail()"
                    #tenPhongBan="ngModel"
                  />
                </td>
                <td
                  class="text-right"
                  style="text-align: right"
                  *ngIf="isDetail() || isUpdate()"
                >
                  {{ item.createDate?.toLocaleDateString("en-GB") }}
                </td>
                <td
                  class="text-right"
                  style="text-align: right"
                  *ngIf="isDetail()"
                >
                  {{ item.updateDate?.toLocaleDateString("en-GB") }}
                </td>
              </tr>
              <tr *ngIf="target.data.length === 0">
                <td colspan="7">Không có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div
          class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right"
          style="text-align: right"
        >
          <ngx-btn-submit
            *ngIf="!isDetail()"
            [disabled]="frm.invalid ? true : false"
          ></ngx-btn-submit>
          &nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
