import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-info',
  templateUrl: './icon-info.component.html',
  styleUrls: ['./icon-info.component.scss'],
})
export class IconInfoComponent {
  constructor() {}
}
