import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  MenuModel,
  RoleModel,
  RoleModelListModelImport,
  UserModel,
} from 'src/app/core/services/qtht.service';
import { Inject } from '@angular/core';
import { Action, SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { SysUserService } from '../../sysUser/sysUser.service';
@Component({
  selector: 'ngx-sysRole-crud',
  templateUrl: './sysRole-crud.component.html',
  styleUrls: ['./sysRole-crud.component.scss'],
})
export class SysRoleCrudComponent implements OnInit {
  constructor(
    private service: SysUserService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: UserModel;
      action: string;
      title: string;
      lstMenu: MenuModel[];
      role: RoleModel[];
    },
    public dialogRef: MatDialogRef<SysRoleCrudComponent>
  ) {}
  target: any = {};
  data_: any[] = [];
  unit: any;
  isImport() {
    return (
      this.target.action === Action.Import || this.target.action === Action.Role
    );
  }
  isUpdate() {
    return this.target.action === Action.Update;
  }
  ngOnInit(): void {
    this.target = this.data;
    this.service
      .GetModelByUserId(this.data.data.userId ?? 0)
      .subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          rest.data?.forEach((element) => {
            this.data_.push(this.setpathMenu(element));
          });
        }
      });
  }
  findPath(element: any) {
    return this.target.lstMenu
      .find((c: { menuId: any }) => c.menuId === element.menuId)
      ?.pathMenu?.toLowerCase();
  }
  setpathMenu(element: any) {
    element['pathMenu'] = this.findPath(element);
    element['time'] = new Date().getTime();
    if (element['pathMenu'] === undefined) {
      this.lstRole.forEach((elt) => {
        element[elt.text] = false;
      });
    } else {
      this.lstRole.forEach((elt) => {
        element[elt.text] = element.roleName.includes(
          this.createRol(element['pathMenu'], elt.text)
        );
      });
    }
    return element;
  }
  createRol(path: string, role: string) {
    return `${path}_${role};`;
  }

  mapRole(item: any, roleName: string) {
    item[roleName] = roleName;
    return item;
  }

  lstRole: any[] = [
    { text: 'Paging' },
    { text: 'Create' },
    { text: 'Update' },
    { text: 'Delete' },
    { text: 'SelectOne' },
    { text: 'Import' },
    { text: 'SelectAll' },
  ];
  getTitle() {
    return (
      this.target.action +
      ' cho tài khoản ' +
      this.target.data.userName
    ).toUpperCase();
  }
  addItem() {
    if (this.data_.length < this.target.lstMenu.length) {
      let role = new RoleModel();
      role.userId = this.target.data.userId;
      this.data_.push(role);
    } else {
      this.commonService.toastrFailure('Số bản ghi đã vượt quá số lượng menu');
    }
  }
  checkAdd() {
    return this.data_.length < this.target.lstMenu.length;
  }
  checkExit(item: any) {
    if (this.data_.filter((c) => c.menuId === item.menuId)?.length > 1) {
      this.commonService.toastrFailure(
        'Bản ghi đã tồn tại. Vui lòng kiểm tra lại'
      );
    }
  }
  lstMenuDelete: any[] = [];

  removeItem(item: any) {
    if (item.userMenuId > 0) {
      this.lstMenuDelete.push(item.userMenuId);
    }
    this.data_.splice(
      this.data_.indexOf(
        (c: { role: { menuId: any } }) => c.role.menuId === item.role.menuId
      ),
      1
    );
  }
  update(frm: any) {
    let body = new RoleModelListModelImport();
    body.data = [];
    this.data_.forEach((element) => {
      let role = new RoleModel();
      role.userMenuId = element.userMenuId;
      role.menuId = element.menuId;
      role.userId = element.userId;
      role.trangThai = 1;
      if (element.pathMenu === undefined)
        element.pathMenu = this.findPath(element);
      role.roleName = element.pathMenu + ';';
      this.lstRole.forEach((rol) => {
        if (element[rol.text] === true)
          role.roleName += this.createRol(element.pathMenu, rol.text);
      });
      body.data?.push(role);
    });
    body.listIdDelete = this.lstMenuDelete;
    this.service.addRole(body).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess(rest.message);
        this.dialogRef.close(true);
      } else {
        this.commonService.toastrFailure(rest.message);
      }
    });
  }
  isDetail() {
    return this.target.action === Action.Detail;
  }
}
