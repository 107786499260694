import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SysLoaiSanPhamIndexComponent } from './sysLoaiSanPham-index/sysLoaiSanPham-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: SysLoaiSanPhamIndexComponent }],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SysLoaiSanPhamRoutingModule {}
