import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Int32ApiResponse,
  LoaiSanPhamModel,
  LoaiSanPhamModelApiResponse,
  LoaiSanPhamModelListApiResponse,
  LoaiSanPhamModelListPageModelViewApiResponse,
  VCVNApiService,
  ResultImportModelApiResponse,
  SysPageModel,
} from 'src/app/core/services/vcvn.service';
import { QTHTApiService } from 'src/app/core/services/qtht.service';

@Injectable({
  providedIn: 'root',
})
export class SysLoaiSanPhamService {
  constructor(private apiQTHT: QTHTApiService, private api: VCVNApiService) {}
  getUnitInfo() {
    return this.apiQTHT.appQthtApiSysUnitExtandGetUnitInfo();
  }
  paging(
    body: SysPageModel
  ): Observable<LoaiSanPhamModelListPageModelViewApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamPaging(body);
  }
  selectAll(): Observable<LoaiSanPhamModelListApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamSelectAll();
  }
  import(
    body: LoaiSanPhamModel[] | undefined
  ): Observable<ResultImportModelApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamImport(body);
  }
  delete(loaiSanPhamId: number): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamDelete(loaiSanPhamId);
  }
  update(body: LoaiSanPhamModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamUpdate(body);
  }
  updateTrangThai(
    body: LoaiSanPhamModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamUpdateTrangThai(body);
  }
  create(body: LoaiSanPhamModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamCreate(body);
  }
  selectOne(loaiSanPhamId: number): Observable<LoaiSanPhamModelApiResponse> {
    return this.api.appVCVNApiLoaiSanPhamSelectOne(loaiSanPhamId);
  }
  selectAllLoaiSanPham() {
    return this.api.appVCVNApiLoaiSanPhamExtandSelectAllBase();
  }
}
