import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { SysLoaiSanPhamIndexComponent } from './sysLoaiSanPham-index/sysLoaiSanPham-index.component';
import { SysLoaiSanPhamCrudComponent } from './sysLoaiSanPham-crud/sysLoaiSanPham-crud.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [SysLoaiSanPhamIndexComponent, SysLoaiSanPhamCrudComponent],
  imports: [
    FormsModule,
    ThemeModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
  ],
})
export class SysLoaiSanPhamModule {}
