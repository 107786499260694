import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Action, SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { SysDanhGiaService } from '../sysDanhGia.service';
@Component({
  selector: 'ngx-sysDanhGia-crud',
  templateUrl: './sysDanhGia-crud.component.html',
  styleUrls: ['./sysDanhGia-crud.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SysDanhGiaCrudComponent implements OnInit {
  constructor(
    private service: SysDanhGiaService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: [];
      action: string;
      title: string;
      lstSanPham: [];
      lstRecord: [];
      menuName: string;
    },
    public dialogRef: MatDialogRef<SysDanhGiaCrudComponent>
  ) {}
  target: any = {};

  unit: any;
  isImport() {
    return this.target.action === Action.Import;
  }
  isUpdate() {
    return this.target.action === Action.Update;
  }

  ngOnInit(): void {
    this.target = this.data;
    if (
      this.target.action === Action.Update ||
      this.target.action === Action.Detail
    ) {
      this.service
        .selectOne(this.target.data[0].danhGiaId)
        .subscribe((rest) => {
          if (rest.code === SUCCESS_RESPONE) {
            this.target.data[0] = rest.data;
            this.getImage();
            this.getNoiDung();
          }
        });
    } else {
      this.target.data.map((element: { tableName: string; rate: number }) => {
        element.rate = 5;
        element.tableName = this.target.menuName;
      });
    }
  }

  getImage() {
    this.target.data[0].image = '../../../assets/images/loading.webp';
    this.service.getImage(this.target.data[0].danhGiaId).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.target.data[0].image = rest.data;
      }
    });
  }
  getNoiDung() {
    this.target.data[0].noiDung = '';
    this.service.getNoiDung(this.target.data[0].danhGiaId).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.target.data[0].nhanXet = rest.data;
      }
    });
  }

  getTitle() {
    return (this.target.action + this.target.title).toUpperCase();
  }

  lstImg: any = [];

  loadData() {}

  uploadImage(event: any, item: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      item.image = reader.result?.toString();
    };
    return item;
  }

  update(frm: any) {
    if (this.target.action === Action.Create) {
      this.service.create(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Update) {
      this.service.update(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Import) {
      this.service.import(this.target.data).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    }
  }

  isDetail() {
    return this.target.action === Action.Detail;
  }
}
