import {
  HttpInterceptor,
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpResponse,
  HttpErrorResponse,
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';
import { map, catchError, throwError, finalize } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { TokenService } from '../token.service';
import { CommonService } from '../customs/common.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  constructor(
    private tokenService: TokenService,
    private authService: AuthService,
    private spinner: NgxSpinnerService,
    private commmonService: CommonService
  ) {}

  pendingRequestsCount = 0;

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    this.pendingRequestsCount++;
    this.spinner.show();
    const token = this.tokenService.getToken();
    request = request.clone({
      setHeaders: {
        'ngrok-skip-browser-warning': 'true',
      },
    });
    if (token) {
      request = request.clone({
        setHeaders: {
          Authorization: 'Bearer ' + token,
        },
      });
    }
    if (!request.url.endsWith('/UploadFile')) {
      if (!request.headers.has('Content-Type')) {
        request = request.clone({
          setHeaders: {
            'content-type': 'application/json',
          },
        });
      }
    }

    request = request.clone({
      headers: request.headers.set('Accept', 'application/json'),
    });

    return next.handle(request).pipe(
      map((event: HttpEvent<any>) => {
        if (event instanceof HttpResponse) {
        }
        return event;
      }),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          this.commmonService.toastrFailure(
            'Phiên làm việc của bạn đã hết vui lòng đăng nhập lại.'
          );
          this.authService.logout();
        } else if (error.status === 0) {
          // setInterval(() => {
          //   window.location.reload();
          // }, 10000);
        } else if (error.status === 403) {
          this.commmonService.toastrFailure('Bạn chưa được phân quyền.');
        } else {
        }
        return throwError((error: any) => {});
      }),
      finalize(() => {
        this.pendingRequestsCount--;
        if (this.pendingRequestsCount === 0) {
          this.spinner.hide();
        }
      })
    );
  }
}
