import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthInterceptor } from './core/interceptor/auth.interceptor';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { LoginLayoutModule } from './theme/pages/login-layout/login-layout.module';
import { environment } from 'src/environments/environment';
import { QTHT_URL } from './core/services/qtht.service';
import { QTHTModule } from './qtht/qtht.module';
import { PagesModule } from './theme/pages/pages.module';
import { VCVN_URL } from './core/services/vcvn.service';
import { NbThemeModule } from '@nebular/theme';

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    AppRoutingModule,
    QTHTModule,
    PagesModule,
    LoginLayoutModule,
    NbThemeModule.forRoot({ name: 'default' }),
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [NgxSpinnerModule],
  bootstrap: [AppComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
    { provide: VCVN_URL, useValue: environment.VCVN_URL },
    { provide: QTHT_URL, useValue: environment.QTHT_URL },
  ],
})
export class AppModule {}
