import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { SysPhongBanIndexComponent } from './sysPhongBan-index/sysPhongBan-index.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SysPhongBanCrudComponent } from './sysPhongBan-crud/sysPhongBan-crud.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [SysPhongBanIndexComponent, SysPhongBanCrudComponent],
  imports: [FormsModule, ThemeModule, MatSlideToggleModule, NgxSpinnerModule],
})
export class SysPhongBanModule {}
