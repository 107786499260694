import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Action,
  LBL_DISPLAY,
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from 'src/app/core/customs/constants';
import {
  ChucDanhModel,
  SysPageModel,
} from 'src/app/core/services/qtht.service';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { DialogConfirmComponent } from 'src/app/theme/components/template/button/dialog/dialog-confirm/dialog-confirm.component';
import { environment } from 'src/environments/environment';
import { SysChucDanhCrudComponent } from '../sysChucDanh-crud/sysChucDanh-crud.component';
import { SysChucDanhService } from '../sysChucDanh.service';

@Component({
  selector: 'ngx-sysChucDanh-index',
  templateUrl: './sysChucDanh-index.component.html',
  styleUrls: ['./sysChucDanh-index.component.scss'],
})
export class SysChucDanhIndexComponent implements OnInit {
  constructor(
    private service: SysChucDanhService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}
  title = 'Quản lý chức danh';
  target: any = {
    data: [],
  };
  unit: any;

  pageModel: SysPageModel = new SysPageModel({
    search: '',
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: '',
    sortColumn: [],
    searchColumn: [],
  });
  lstPhongBan: any = [];

  ngOnInit(): void {
    this.loadData();
    this.service.getUnitInfo().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.unit = ret.data;
      }
    });
    this.service.selectAllBasePhongBan().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.lstPhongBan = rest.data;
      }
    });
  }
  searchPage() {
    this.pageModel.currentPage = 1;
    this.loadData();
  }

  resetPage() {
    this.pageModel.search = '';
    this.pageModel.searchColumn = [];
    this.pageModel.sortColumn = [];
    this.loadData();
  }
  create() {
    this.dialog
      .open(
        SysChucDanhCrudComponent,
        this.commonService.configDialog('80%', {
          action: Action.Create,
          data: [new ChucDanhModel()],
          title: this.title,
          lstPhongBan: this.lstPhongBan,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) this.loadData();
      });
  }

  loadData() {
    this.service.padingBase(this.pageModel).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.target.data = rest.data;
      }
    });
  }

  getSTT(index: number) {
    return (
      (this.pageModel.pageSize ?? 0) * ((this.pageModel.currentPage ?? 1) - 1) +
      index +
      1
    );
  }

  downLoadTemplate() {
    this.commonService.downloadFileTemPlate('SysChucDanh');
  }
  getTenPhongBan(phongBanId: number) {
    return this.lstPhongBan.find(
      (c: { phongBanId: number }) => c.phongBanId === phongBanId
    )?.tenPhongBan;
  }
  genTable(data: any) {
    let body = `<table class="table table-bordered table-hover" id="customers">
    <thead>
      <tr>
        <th class="text-center">STT</th>
        <th class="text-center">Phòng ban</th>
        <th class="text-center">Chức danh</th>
        <th class="text-center">Trạng thái</th>
        <th class="text-center">Ngày tạo</th>
        <th class="text-center">Ngày cập nhật</th>
      </tr>
    </thead>
    <tbody>`;
    if (data.length === 0) {
      body = `<tr><td colspan="3">Không có dữ liệu</td></tr>`;
    } else {
      data.forEach(
        (
          item: {
            phongBanId: number;
            tenChucDanh: string;
            trangThai: number;
            createDate: Date;
            updateDate: Date;
          },
          index: string
        ) => {
          body += '<tr>';
          body += `<td class="text-center">` + index + `</td>`;
          body +=
            `<td class="text-left">` +
            this.getTenPhongBan(item.phongBanId) +
            `</td>`;
          body += `<td class="text-left">` + item.tenChucDanh + `</td>`;
          body +=
            `<td class="text-center">` +
            (item.trangThai === 1 ? 'Hiển thị' : 'Không hiển thị') +
            `</td>`;
          body +=
            `<td class="text-right" style="text-align: right">` +
            item.createDate?.toLocaleDateString('en-GB') +
            `</td>`;
          body +=
            `<td class="text-right" style="text-align: right">` +
            item.updateDate?.toLocaleDateString('en-GB') +
            `</td>`;
          body += '</tr>';
        }
      );
    }
    body += `</tbody>
  </table>`;
    return body;
  }
  getHtml(data: any) {
    return this.commonService.generalHtml(
      this.title,
      this.genTable(data),
      this.unit?.unitName ?? ''
    );
  }
  printWord() {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.print,
          message: LBL_DISPLAY.confirmAll,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.printAll();
        }
      });
  }
  dataToPrint(data: any) {
    const html = this.getHtml(data);
    let newWin = window.open(this.title);
    newWin?.document.write(html);
    newWin?.print();
    newWin?.close();
  }
  exportPdf() {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.exportPdf,
          message: LBL_DISPLAY.confirmAll,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.pdfAll();
        }
      });
  }
  pdfAll() {
    this.service.selectAll().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.dataToPdf(ret.data);
      }
    });
  }
  dataToPdf(data: any) {
    // const htmlString = this.getHtml(data);
    // var div = document.createElement('div');
    // div.id = 'testing';
    // div.innerHTML += htmlString;
    // document.body.appendChild(div);
    //  (document.getElementById('testing') ?? new HTMLElement()).then(
    //   (canvas) => {
    //     var data = canvas.toDataURL();
    //     var docDefinition = {
    //       content: [
    //         {
    //           image: data,
    //           width: 520,
    //         },
    //       ],
    //     };
    //     pdfMake.createPdf(docDefinition).download(this.title + '.pdf');
    //     document.body.removeChild(div);
    //   }
    // );
  }

  importExcel(files: any) {
    if (
      files.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      files.target.files[0].type === 'application/vnd.ms-excel'
    ) {
      const targetfiles: DataTransfer = <DataTransfer>files.target;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let dataExcel: [][] = [];
        dataExcel = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let data = [];
        if (dataExcel[2]) {
          for (let i = 3; i < dataExcel.length; i++) {
            if (dataExcel[i].length > 0) {
              let item_ = new ChucDanhModel();
              let index = 0;
              const item = dataExcel[i]?.map((item1: any) => item1);
              item_.tenChucDanh = item[1];
              if (!this.commonService.checkObjectNull(item_)) data.push(item_);
            }
          }
          this.dialog
            .open(
              SysChucDanhCrudComponent,
              this.commonService.configDialog('80%', {
                data: data,
                action: Action.Import,
                title: this.title,
              })
            )
            .afterClosed()
            .subscribe(() => {
              this.loadData();
            });
        }
      };
      reader.readAsBinaryString(targetfiles.files[0]);
    } else {
      this.commonService.toastrFailure('File import không đúng định dạng!');
    }
  }

  dataToExcel(data: any) {
    const workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Dữ liệu', {
      pageSetup: { paperSize: Excel.PaperSize.A4 },
    });
    worksheet.columns = [
      {
        header: [this.title, '', 'STT'],
        key: 'stt',
        width: environment.with_export,
      },
      {
        header: [this.title, '', 'Phòng ban'],
        key: 'tenPhongBan',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Chức danh'],
        key: 'tenChucDanh',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Trạng thái'],
        key: 'trangThai',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Ngày tạo'],
        key: 'createDate',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Ngày cập nhật'],
        key: 'updateDate',
        width: 3 * environment.with_export,
      },
    ];
    worksheet.mergeCells(1, 1, 1, worksheet.columns.length);
    worksheet.getCell(1, 1).style = {
      font: { bold: true, name: 'Times New Roman', size: 14 },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
    };
    data?.forEach((e: any, index: number) => {
      worksheet.addRow(
        {
          stt: index + 1,
          tenPhongBan: this.getTenPhongBan(e?.phongBanId),
          tenChucDanh: e?.tenChucDanh,
          pathChucDanh: e?.pathChucDanh,
          trangThai: e?.trangThai === 1 ? 'Hiển thị' : 'Không hiển thị',
          createDate: e?.createDate?.toLocaleDateString('en-GB'),
          updateDate: e?.updateDate?.toLocaleDateString('en-GB'),
        },
        'n'
      );
    });
    worksheet = this.commonService.setStyleExport(
      worksheet,
      1,
      worksheet.columns.length,
      3,
      3,
      [1, 4],
      [5, 6]
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, this.title + '.xlsx');
    });
  }
  exportExcel() {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.exportFile,
          message: LBL_DISPLAY.confirmAll,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.exportAll();
        }
      });
  }
  printAll() {
    this.service.selectAll().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.dataToPrint(ret.data);
      }
    });
  }
  exportAll() {
    this.service.selectAll().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.dataToExcel(ret.data);
      }
    });
  }
}
