import { Component } from '@angular/core';

@Component({
  selector: 'ngx-icon-edit',
  templateUrl: './icon-edit.component.html',
  styleUrls: ['./icon-edit.component.scss'],
})
export class IconEditComponent {
  constructor() {}
}
