<form #frm="ngForm" (ngSubmit)="update(frm)">
  <nb-card *ngFor="let item of target.data; let index">
    <nb-card-header>
      <h1>{{ getTitle() }}</h1>
    </nb-card-header>
    <nb-card-body style="max-height: 60vh; overflow: auto">
      <div class="row">
        <div class="col">
          <label for="chuDe" class="required">Chủ đề bài viết</label>
          <ng-select
            name="lstdich-vu"
            [items]="target.lstChuDe"
            bindValue="loaiSanPhamId"
            bindLabel="tenLoaiSanPham"
            placeholder="Chọn chủ đề"
            [multiple]="false"
            [closeOnSelect]="true"
            [(ngModel)]="item.chuDe"
            [clearOnBackspace]="false"
            appendTo="body"
            [clearSearchOnAdd]="true"
            [disabled]="isDetail()"
          >
          </ng-select>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col">
          <label for="tieuDe" class="required">Tiêu đề bài viết</label>
          <input
            type="text"
            class="form-control"
            [(ngModel)]="item.tieuDe"
            name="tieuDe"
            maxlength="200"
            #tieuDe="ngModel"
            placeholder="Tiêu đề bài viết"
            id="tieuDe"
            required
            [disabled]="isDetail()"
          />
        </div>
      </div>
      &nbsp;

      <div class="row">
        <div class="col">
          <label class="required">Ảnh</label>
          <ngx-btn-file
            (change)="uploadImage($event, item)"
            [multiple]="false"
            *ngIf="!isDetail()"
          ></ngx-btn-file>
          <img src="{{ item.image }}" width="90px" height="auto" />
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col">
          <label for="tomTat" class="required">Tóm tắt</label>
          <quill-editor
            [modules]="modules"
            [(ngModel)]="item.tomTat"
            name="tomTat"
            [disabled]="isDetail()"
            [required]="true"
          ></quill-editor>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col">
          <label for="noiDung" class="required">Nội dung</label>
          <quill-editor
            [modules]="modules"
            [(ngModel)]="item.noiDung"
            name="noiDung"
            [disabled]="isDetail()"
            [required]="true"
          ></quill-editor>
        </div>
      </div>
      &nbsp;
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col text-right" style="text-align: right">
          <ngx-btn-submit
            *ngIf="!isDetail()"
            [disabled]="frm.invalid === true"
          ></ngx-btn-submit>
          &nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
