import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SysPhongBanService } from '../sysPhongBan.service';
import { Inject } from '@angular/core';
import { Action, SUCCESS_RESPONE } from 'src/app/core/customs/constants';
@Component({
  selector: 'ngx-sysPhongBan-crud',
  templateUrl: './sysPhongBan-crud.component.html',
  styleUrls: ['./sysPhongBan-crud.component.scss'],
})
export class SysPhongBanCrudComponent implements OnInit {
  constructor(
    private service: SysPhongBanService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: [];
      tuDien: [];
      action: string;
      title: string;
      lstTrangThai: [];
    },
    public dialogRef: MatDialogRef<SysPhongBanCrudComponent>
  ) {}
  target: any = {};
  unit: any;
  isImport() {
    return this.target.action === Action.Import;
  }
  isUpdate() {
    return this.target.action === Action.Update;
  }
  ngOnInit(): void {
    this.target = this.data;
  }
  getTitle() {
    return (this.target.action + this.target.title).toUpperCase();
  }
  loadData() {}

  update(frm: any) {
    if (this.target.action === Action.Create) {
      this.service.create(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Update) {
      this.service.update(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Import) {
      this.service.import(this.target.data).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    }
  }
  isDetail() {
    return this.target.action === Action.Detail;
  }
}
