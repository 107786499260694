<!-- login.component.html -->
<nb-layout>
  <nb-layout-column>
    <ngx-spinner size="large" type="ball-scale-multiple"></ngx-spinner>
    <section class="vh-100 gradient">
      <div class="container py-5 h-100">
        <div class="row d-flex justify-content-center align-items-center h-100">
          <div class="col-12 col-md-8 col-lg-6 col-xl-5">
            <div class="card shadow-2-strong" style="border-radius: 1rem">
              <div class="card-body p-4 p-md-5 text-center">
                <h3 class="mb-4 mb-md-5" style="color: #dd4b39">
                  QUẢN TRỊ HỆ THỐNG
                </h3>
                <img
                  src="../../../../assets/images/logo.png"
                  alt="Vận chuyển Việt Nam"
                  class="mx-auto"
                  style="height: 10vh"
                />
                <hr class="my-3 my-md-4" />
                <form (ngSubmit)="login()" #loginForm="ngForm">
                  <div class="form-outline mb-3">
                    <input
                      class="form-control"
                      type="text"
                      style="border-radius: 5px"
                      name="username"
                      placeholder="Tên đăng nhập"
                      [(ngModel)]="user.userName"
                      #focusUsername
                      autocomplete="off"
                      required
                    />
                  </div>

                  <div class="form-outline mb-3">
                    <input
                      class="form-control"
                      type="password"
                      style="border-radius: 5px"
                      name="password"
                      placeholder="Mật khẩu"
                      [(ngModel)]="user.password"
                      autocomplete="off"
                      required
                    />
                  </div>
                  <button
                    class="btn btn-success"
                    style="width: 100%"
                    [disabled]="!loginForm.valid"
                  >
                    Đăng nhập
                  </button>
                </form>
                <hr class="my-3 my-md-4" />
                <div class="input-group" title="Trang chủ">
                  <a routerLink="/"
                    ><i class="fa fa-home" aria-hidden="true"></i></a
                  >&nbsp;
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </nb-layout-column>
</nb-layout>
