<ngx-spinner size="large" type="ball-scale-multiple"> </ngx-spinner>
<form #frm="ngForm" (ngSubmit)="update(frm)">
  <nb-card>
    <nb-card-header class="nb-card-header">
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          <b class="tittle-color">{{ getTitle() }}</b>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body>
      <div class="row" style="padding-top: 20px">
        <div
          class="col-md-12 col-sm-12 col-xs-12 col-lg-12"
          style="max-height: 300px; overflow: auto"
        >
          <table class="table table-bordered table-hover">
            <thead>
              <tr>
                <th class="text-center">STT</th>
                <th class="text-center">Tên menu</th>
                <th class="text-center">Phân trang</th>
                <th class="text-center">Thêm mới</th>
                <th class="text-center">Cập nhật</th>
                <th class="text-center">Xóa bản ghi</th>
                <th class="text-center">Xem chi tiết</th>
                <th class="text-center">Import excel</th>
                <th class="text-center">Export excel</th>
                <th>
                  <ngx-btn-add-row
                    (click)="addItem()"
                    *ngIf="checkAdd()"
                  ></ngx-btn-add-row>
                </th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let item of data_; let i = index">
                <td class="text-center">
                  {{ i + 1 }}
                </td>
                <td class="text-center">
                  <ng-select
                    style="min-width: 200px"
                    name="lstMenu{{ i }}_{{ item.time }}"
                    [items]="target.lstMenu"
                    bindValue="menuId"
                    bindLabel="menuName"
                    placeholder="Chọn menu"
                    [multiple]="false"
                    [closeOnSelect]="true"
                    [(ngModel)]="item.menuId"
                    [clearOnBackspace]="false"
                    appendTo="body"
                    [clearSearchOnAdd]="true"
                    (change)="checkExit($event)"
                  >
                  </ng-select>
                </td>
                <td
                  class="text-center"
                  *ngFor="let rol of lstRole; let j = index"
                >
                  <mat-slide-toggle
                    [color]="'primary'"
                    [disabled]="isDetail()"
                    name="{{ i }}_{{ rol.text }}_{{ item.time }}_{{ j }}"
                    [(ngModel)]="item[rol.text]"
                  >
                  </mat-slide-toggle>
                </td>

                <td class="text-center" *ngIf="isImport()">
                  <ngx-btn-delete-row
                    (click)="removeItem(item)"
                    style="cursor: pointer; color: blue"
                  ></ngx-btn-delete-row>
                </td>
              </tr>
              <tr *ngIf="data_?.length === 0">
                <td colspan="7">Không có dữ liệu</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div
          class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right"
          style="text-align: right"
        >
          <ngx-btn-submit *ngIf="!isDetail()"></ngx-btn-submit>
          &nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
  </nb-card>
</form>
