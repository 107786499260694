import { Component, ViewEncapsulation } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { SysRecordService } from '../sysRecord.service';
import { Inject } from '@angular/core';
import { Action, SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import 'quill-emoji';
import Quill from 'quill';
import BlotFormatter from 'quill-blot-formatter';

@Component({
  selector: 'ngx-sysRecord-crud',
  templateUrl: './sysRecord-crud.component.html',
  styleUrls: ['./sysRecord-crud.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class SysRecordCrudComponent {
  constructor(
    private service: SysRecordService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: [];
      tuDien: [];
      action: string;
      title: string;
      lstChuDe: [];
    },
    public dialogRef: MatDialogRef<SysRecordCrudComponent>
  ) {}
  ngOnDestroy(): void {}
  modules = {
    'emoji-shortname': true,
    'emoji-textarea': false,
    'emoji-toolbar': true,
    blotFormatter: {},
  };
  target: any = {};
  unit: any;

  isImport() {
    return this.target.action === Action.Import;
  }
  isUpdate() {
    return this.target.action === Action.Update;
  }
  ngOnInit(): void {
    Quill.register('modules/blotFormatter', BlotFormatter);
    this.target = this.data;
    if (
      this.target.action === Action.Update ||
      this.target.action === Action.Detail
    ) {
      this.service
        .selectOnce(this.target.data[0].recordId ?? 0)
        .subscribe((rest) => {
          if (rest.code === SUCCESS_RESPONE) {
            this.target.data[0] = rest.data;
            this.getImage();
            this.getNoiDung();
          } else {
            this.commonService.toastrFailure(rest.message ?? '');
            this.dialogRef.close();
          }
        });
    }
  }
  getImage() {
    this.target.data[0].image = '../../../assets/images/loading.webp';
    this.service.getImage(this.target.data[0].recordId).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.target.data[0].image = rest.data;
      }
    });
  }
  getNoiDung() {
    this.target.data[0].noiDung = '';
    this.service.getNoiDung(this.target.data[0].recordId).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.target.data[0].noiDung = rest.data;
      }
    });
  }
  uploadImage(event: any, item: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      item.image = reader.result?.toString();
    };
    return item;
  }
  getTitle() {
    return `${this.target.action} ${
      this.target.data[0].tieuDe ?? ''
    }`.toUpperCase();
  }
  loadData() {}

  update(frm: any) {
    if (this.target.action === Action.Create) {
      this.service.insert(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Update) {
      this.service.update(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    }
  }
  isDetail() {
    return this?.target?.action === Action.Detail;
  }
}
