import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { Inject } from '@angular/core';
import { Action, SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { SysLoaiSanPhamService } from '../sysLoaiSanPham.service';
@Component({
  selector: 'ngx-sysLoaiSanPham-crud',
  templateUrl: './sysLoaiSanPham-crud.component.html',
  styleUrls: ['./sysLoaiSanPham-crud.component.scss'],
})
export class SysLoaiSanPhamCrudComponent implements OnInit {
  constructor(
    private service: SysLoaiSanPhamService,
    private commonService: CommonService,
    @Inject(MAT_DIALOG_DATA)
    private data: {
      data: [];
      tuDien: [];
      action: string;
      title: string;
      lstTrangThai: [];
    },
    public dialogRef: MatDialogRef<SysLoaiSanPhamCrudComponent>
  ) {}
  target: any = {};
  unit: any;
  isImport() {
    return this.target.action === Action.Import;
  }
  isUpdate() {
    return this.target.action === Action.Update;
  }
  ngOnInit(): void {
    this.target = this.data;
    if (
      this.target.action === Action.Update ||
      this.target.action === Action.Detail
    ) {
      this.service
        .selectOne(this.target.data[0].loaiSanPhamId)
        .subscribe((rest) => {
          if (rest.code === SUCCESS_RESPONE) {
            this.target.data[0] = rest.data;
          }
        });
    }
  }
  getTitle() {
    return (this.target.action + this.target.title).toUpperCase();
  }
  loadData() {}
  removeItem(item: any) {}

  update(frm: any) {
    if (this.target.action === Action.Create) {
      this.service.create(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Update) {
      this.service.update(this.target.data[0]).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    } else if (this.target.action === Action.Import) {
      this.service.import(this.target.data).subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
    }
  }

  isDetail() {
    return this.target.action === Action.Detail;
  }
}
