import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { UserInfo, UserModelRepones } from './services/qtht.service';
import { CookieService } from 'ngx-cookie';
export const ACCESS_TOKEN = `${window.location.origin}_TOKEN`;
export const USER_INFO = `${window.location.origin}_INFO`;
@Injectable({
  providedIn: 'root',
})
export class TokenService {
  constructor(private cookieService: CookieService) {}
  addMinutes(minutes: any) {
    return new Date(new Date().getTime() + minutes * 60000);
  }
  setUserReponse(userModel: UserModelRepones, timeExpire: any) {
    this.setUserInfo(userModel?.userInfo ?? new UserInfo(), timeExpire);
  }

  setUserInfo(userInfo: UserInfo, timeExpire: Date) {
    this.cookieService.putObject(USER_INFO, userInfo, {
      expires: timeExpire ?? this.addMinutes(5),
    });
  }

  getUserInfo(): Observable<UserInfo> {
    const us = this.cookieService.getObject(USER_INFO);
    return of(us as UserInfo);
  }

  removeCookie() {
    this.cookieService.removeAll();
  }
  getToken(): string {
    return localStorage.getItem(ACCESS_TOKEN) ?? '';
  }

  saveToken(token: any): void {
    localStorage.setItem(ACCESS_TOKEN, token?.token ?? '');
  }
  saveTokenSSO(token: any): void {
    localStorage.setItem(ACCESS_TOKEN, token ?? '');
  }

  removeToken(): void {
    localStorage.removeItem(ACCESS_TOKEN);
  }
}
