<ngx-spinner size="large" type="ball-scale-multiple"></ngx-spinner>
<nb-card>
  <nb-card-header>
    <h1>{{ title }}</h1>
  </nb-card-header>
  <nb-card-body>
    <div class="input-group">
      <ngx-btn-import-file
        (change)="importExcel($event, 'record')"
        [multiple]="false"
      ></ngx-btn-import-file>
      <ngx-btn-export-file
        (click)="exportExcel('record')"
      ></ngx-btn-export-file>
      <ngx-btn-export-pdf (click)="exportPdf('record')"></ngx-btn-export-pdf>
      <ngx-btn-print (click)="printWord('record')"></ngx-btn-print>
    </div>
    <input
      type="text"
      class="form-control"
      placeholder="Nhập nội dung tìm kiếm"
      [(ngModel)]="pageModel1.search"
      (keyup.enter)="loadData1()"
      name="search"
      #search="ngModel"
    />
    <div class="row">
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 text-left">
        <ngx-btn-add (click)="create('record')" text="Thêm mới"></ngx-btn-add>
      </div>
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 text-right">
        <ngx-btn-search (click)="searchPage1()"></ngx-btn-search>
        <ngx-btn-refresh (click)="resetPage1()"></ngx-btn-refresh>
      </div>
    </div>
    <div id="tablePrint">
      <table class="table table-bordered table-hover">
        <thead>
          <tr>
            <th class="text-center">STT</th>
            <th class="text-center">Tên</th>
            <th class="text-center">Số điện thoại</th>
            <th class="text-center">Tiêu đề bài viết</th>
            <th class="text-center">Đánh giá</th>
            <th class="text-center">Trạng thái</th>
            <th class="text-center">Ngày tạo</th>
            <th class="text-center">Ngày cập nhật</th>
            <th class="text-center">Thao tác</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of target1.data; let i = index">
            <td class="text-center">{{ getSTT1(i) }}</td>
            <td class="text-left">{{ item.ten }}</td>
            <td class="text-left">{{ item.sdt }}</td>
            <td
              class="text-left"
              style="max-height: 50vh; max-width: 50vh; overflow: auto"
            >
              {{ getTenRecord(item.modelId) }}
            </td>
            <td class="text-center" style="max-height: 50vh; overflow: auto">
              <ngb-rating
                [(rate)]="item.rate"
                [max]="5"
                [readonly]="true"
              ></ngb-rating>
            </td>
            <td class="text-center">
              <mat-slide-toggle
                [color]="'primary'"
                [(ngModel)]="item.trangThai"
                [disabled]="false"
                (change)="changeStatus(item)"
              ></mat-slide-toggle>
            </td>
            <td class="text-right" style="text-align: right">
              {{ item.createDate?.toLocaleDateString("en-GB") }}
            </td>
            <td class="text-right" style="text-align: right">
              {{ item.updateDate?.toLocaleDateString("en-GB") }}
            </td>
            <td class="text-center">
              <ngx-icon-info
                (click)="viewDetail(item, 'record')"
              ></ngx-icon-info
              >&nbsp;&nbsp;
              <ngx-icon-edit
                (click)="viewUpdate(item, 'record')"
              ></ngx-icon-edit
              >&nbsp;&nbsp;
              <ngx-icon-delete (click)="viewDelete(item)"></ngx-icon-delete>
            </td>
          </tr>
          <tr *ngIf="target1.data?.length === 0">
            <td colspan="3">Không có dữ liệu</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="row" style="float: right">
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
        <ngx-pagination
          (pageChange)="pageModel1.currentPage = $event; loadData1()"
          (pageSize)="pageModel1.pageSize = $event; loadData1()"
          [currentPage]="pageModel1.currentPage ?? 1"
          [totalPages]="target1.totalPage ?? 0"
        ></ngx-pagination>
      </div>
    </div>
  </nb-card-body>
</nb-card>
