import { CommonModule } from '@angular/common';
import { ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { MatDialogModule } from '@angular/material/dialog';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTabsModule } from '@angular/material/tabs';
import {
  NbActionsModule,
  NbButtonModule,
  NbCardModule,
  NbContextMenuModule,
  NbIconModule,
  NbLayoutModule,
  NbListModule,
  NbMenuModule,
  NbRadioModule,
  NbSearchModule,
  NbSelectModule,
  NbSidebarModule,
  NbUserModule,
} from '@nebular/theme';
import { NgSelectModule } from '@ng-select/ng-select';

import { MdPaginationComponent } from './components/pagination/pagination.component';
import { BtnAddComponent } from './components/template/button/add/btn-add.component';
import { BtnCloseComponent } from './components/template/button/close/btn-close.component';
import { BtnConfirmComponent } from './components/template/button/confirm/btn-confirm.component';
import { BtnDeleteComponent } from './components/template/button/delete/btn-delete.component';
import { BtnDownloadTemplateComponent } from './components/template/button/download-template/btn-download-template.component';
import { BtnFileComponent } from './components/template/button/file/btn-file.component';
import { BtnImportFileComponent } from './components/template/button/import-file/btn-import-file.component';
import { BtnRefreshComponent } from './components/template/button/refresh/btn-refresh.component';
import { BtnSaveComponent } from './components/template/button/save/btn-save.component';
import { BtnSearchComponent } from './components/template/button/search/btn-search.component';
import { BtnSubmitComponent } from './components/template/button/submit/btn-submit.component';
import { IconDeleteComponent } from './components/template/icon/delete/icon-delete.component';
import { IconEditComponent } from './components/template/icon/edit/icon-edit.component';
import { IconInfoComponent } from './components/template/icon/info/icon-info.component';
import { IconSiteMapComponent } from './components/template/icon/site-map/icon-site-map.component';
import { NbEvaIconsModule } from '@nebular/eva-icons';
import {
  CapitalizePipe,
  NumberWithCommasPipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithDotPipe,
} from './pipes';
import { BtnAddRowComponent } from './components/template/button/add-row/btn-add-row.component';
import { BtnDeleteRowComponent } from './components/template/button/delete-row/btn-delete-row.component';
import { IconResetPassComponent } from './components/template/icon/reset-pass/icon-reset-pass.component';
import { BtnPrintComponent } from './components/template/button/print/btn-print.component';
import { DialogConfirmComponent } from './components/template/button/dialog/dialog-confirm/dialog-confirm.component';
import { ConvertDatePipe } from '../core/customs/common.service';
import { NbSecurityModule } from '@nebular/security';
import { BtnExportFileComponent } from './components/template/button/export-file/btn-export-file.component';
import { BtnExportPdfComponent } from './components/template/button/export-pdf/btn-export-pdf.component';
import { HomeHeaderComponent } from './components/home-header/home-header.component';
import { BtnPhoneComponent } from './components/template/button/phone/btn-phone.component';
import { BtnCalendarComponent } from './components/template/button/calendar/btn-calendar.component';
import { OneColumnLayoutComponent } from './layouts/one-column/one-column.layout';
import { HomeFooterComponent } from './components/home-footer/home-footer.component';
import { ChangePasswordComponent } from './pages/change-password/change-password.component';
import { UserInfoComponent } from './pages/user-info/user-info.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
const BASE_MODULES = [CommonModule, FormsModule, NgSelectModule];
const MAT_MODULES = [MatDialogModule, MatTabsModule, MatStepperModule];
const NB_MODULES = [
  NbListModule,
  NbRadioModule,
  NbCardModule,
  NbLayoutModule,
  NbMenuModule,
  NbUserModule,
  NbActionsModule,
  NbSearchModule,
  NbSidebarModule,
  NbContextMenuModule,
  NbSecurityModule,
  NbButtonModule,
  NbSelectModule,
  NbIconModule,
  NbEvaIconsModule,
];
const COMPONENTS = [
  UserInfoComponent,
  ChangePasswordComponent,
  HeaderComponent,
  FooterComponent,
  OneColumnLayoutComponent,
  MdPaginationComponent,
  BtnSubmitComponent,
  BtnAddComponent,
  BtnCloseComponent,
  BtnRefreshComponent,
  BtnSaveComponent,
  BtnPrintComponent,
  BtnSearchComponent,
  BtnFileComponent,
  BtnDeleteComponent,
  BtnConfirmComponent,
  BtnImportFileComponent,
  BtnExportFileComponent,
  BtnExportPdfComponent,
  BtnDownloadTemplateComponent,
  BtnAddRowComponent,
  BtnDeleteRowComponent,
  IconInfoComponent,
  IconEditComponent,
  IconDeleteComponent,
  IconResetPassComponent,
  IconSiteMapComponent,
  DialogConfirmComponent,
  HomeFooterComponent,
  HomeHeaderComponent,
  BtnPhoneComponent,
  BtnCalendarComponent,
];
const PIPES = [
  CapitalizePipe,
  PluralPipe,
  RoundPipe,
  TimingPipe,
  NumberWithCommasPipe,
  ConvertDatePipe,
  NumberWithDotPipe,
];

@NgModule({
  imports: [...BASE_MODULES, ...NB_MODULES, ...MAT_MODULES],
  exports: [
    ...BASE_MODULES,
    ...NB_MODULES,
    ...MAT_MODULES,
    ...PIPES,
    ...COMPONENTS,
  ],
  declarations: [...COMPONENTS, ...PIPES],
  providers: [],
})
export class ThemeModule {
  static forRoot(): ModuleWithProviders<ThemeModule> {
    return {
      ngModule: ThemeModule,
    };
  }
}
