import { TokenService } from './../token.service';
import { DatePipe } from '@angular/common';
import { Component, Injectable, Pipe, PipeTransform } from '@angular/core';
import { MatDialogConfig } from '@angular/material/dialog';
import { NbToastrService } from '@nebular/theme';
import {
  FAILURE_NOTICE,
  HEIGHT_DIALOG,
  SUCCESS_NOTICE,
  TITLE_NOTICE,
  WIDTH_DIALOG,
} from './constants';
import { Worksheet } from 'exceljs';
import { VCVNApiService } from '../services/vcvn.service';

@Injectable({
  providedIn: 'root',
})
export class CommonService {
  isVietnamesePhoneNumber(number: any) {
    return /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/.test(number);
  }
  setStyleExport(
    worksheet: Worksheet,
    colStart: number,
    colEnd: number,
    rowHeaderStart: number,
    rowHeaderEnd: number,
    colCenter?: number[],
    colRight?: number[],
    colBold?: number[]
  ): Worksheet {
    worksheet.eachRow((row, rowNumber) => {
      for (let colNumber = colStart; colNumber <= colEnd; colNumber++) {
        if (rowNumber >= rowHeaderStart && rowNumber <= rowHeaderEnd) {
          worksheet.getCell(rowNumber, colNumber).style = {
            border: {
              top: { style: 'thin' },
              bottom: { style: 'thin' },
              left: { style: 'thin' },
              right: { style: 'thin' },
            },
            font: { size: 10, name: 'Times New Roman', bold: true },
            alignment: {
              wrapText: true,
              vertical: 'middle',
              horizontal: 'center',
            },
          };
        } else if (rowNumber > rowHeaderEnd) {
          if (colCenter?.includes(colNumber)) {
            worksheet.getCell(rowNumber, colNumber).style = {
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
              },
              font: {
                size: 10,
                name: 'Times New Roman',
                bold:
                  colBold && colBold.length > 0
                    ? colBold[colNumber - 1] === colNumber
                    : false,
              },
              alignment: {
                wrapText: true,
                vertical: 'middle',
                horizontal: 'center',
              },
            };
          } else if (colRight?.includes(colNumber)) {
            worksheet.getCell(rowNumber, colNumber).style = {
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
              },
              font: {
                size: 10,
                name: 'Times New Roman',
                bold:
                  colBold && colBold.length > 0
                    ? colBold[colNumber - 1] === colNumber
                    : false,
              },
              alignment: {
                wrapText: true,
                vertical: 'middle',
                horizontal: 'right',
              },
            };
            worksheet.getCell(rowNumber, colNumber).numFmt = '#,##0';
          } else {
            worksheet.getCell(rowNumber, colNumber).style = {
              border: {
                top: { style: 'thin' },
                bottom: { style: 'thin' },
                left: { style: 'thin' },
                right: { style: 'thin' },
              },
              font: {
                size: 10,
                name: 'Times New Roman',
                bold:
                  colBold && colBold.length > 0
                    ? colBold[colNumber - 1] === colNumber
                    : false,
              },
              alignment: {
                wrapText: true,
                vertical: 'middle',
                horizontal: 'left',
              },
            };
          }
        }
      }
    });
    return worksheet;
  }
  checkObjectNull(object: any) {
    let check = 0;
    for (const key of Object.keys(object)) {
      if (
        object[key] === undefined ||
        object[key] === null ||
        object[key].toString().trim() === '' ||
        key === 'id' ||
        key === 'unitcode'
      ) {
        check++;
      }
    }
    return check === Object.keys(object).length;
  }
  generalHtml(title: string, table: string, unitName: string) {
    let html =
      `
      <style>
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: white;
}
</style>
<style>
body {
}
</style>
      <style>
        .footer {
          position: fixed;
          left: 0;
          bottom: 0;
          width: 100%;
          color: black;
          text-align: right;
       }
#customers {
  font-family: Arial, Helvetica, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

#customers td, #customers th {
  border: 1px solid #ddd;
  padding: 8px;
}

#customers tr:nth-child(even){background-color: #f2f2f2;}

#customers tr:hover {background-color: #ddd;}

#customers th {
  padding-top: 12px;
  padding-bottom: 12px;
  text-align: left;
  background-color: #04AA6D;
  color: black;
}
</style>
      <body style="font-size: 18;">
      <div class="container" id="tablePrint">
      
    <div class="row">
      <div class="col">
      <h1 style="text-align: center">` +
      title +
      `</h1>
      </div>
    </div>
        <div class="row">
          <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">` +
      table +
      `
          </div>
        </div>
        <div class="row"><div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <div class="footer">
  <p>` +
      unitName +
      `</p>
</div></div></div>
</div>
      </body>
    `;
    return html;
  }
  constructor(
    private toastrService: NbToastrService,
    private tokenService: TokenService,
    private mctvnApiService: VCVNApiService
  ) {}

  getUserInfo() {
    return this.tokenService.getUserInfo();
  }
  downloadFileTemPlate(menuName: string) {
    this.mctvnApiService
      .appVCVNApiFileExtandDowloadFileTemplate(menuName)
      .subscribe((response) => {
        if (response.status === 200) {
          let type = '';
          let data = '';
          if (response) {
            if (response.headers) {
              type = response.headers['content-type'];
              data = response.headers['x-file-name'];
            }
          }
          const blob = new Blob([response.data], {
            type: type,
          });
          const a = document.createElement('a');
          a.setAttribute('style', 'display:none;');
          document.body.appendChild(a);
          a.download = this.b64DecodeUnicode(data);
          a.href = URL.createObjectURL(blob);
          a.target = '_blank';
          a.click();
          document.body.removeChild(a);
        } else {
          this.toastrFailure('Lấy file thất bại.');
        }
      });
  }
  downloadFile(fileId: string): void {
    // this.appQthtApiService.appQthtApiFileDownloadfile(fileId).subscribe(response => {
    //   const blob = new Blob([response.data], { type: response.headers['content-type'] });
    //   const a = document.createElement('a');
    //   a.setAttribute('style', 'display:none;');
    //   document.body.appendChild(a);
    //   a.download = this.b64DecodeUnicode(response.headers['x-file-name']);
    //   a.href = URL.createObjectURL(blob);
    //   a.target = '_blank';
    //   a.click();
    //   document.body.removeChild(a);
    //   if (response.status !== 200) {
    //     this.toastrDanger('Lỗi khi thực hiện tác vụ download.');
    //   }
    // });
  }

  downloadFileHDSD(appdinhdanh: string): void {
    // this.appQthtApiService.appQthtApiFileDownloadfilehdsd(appdinhdanh).subscribe(response => {
    //   const blob = new Blob([response.data], { type: response.headers['content-type'] });
    //   const a = document.createElement('a');
    //   a.setAttribute('style', 'display:none;');
    //   document.body.appendChild(a);
    //   a.download = this.b64DecodeUnicode(response.headers['x-file-name']);
    //   a.href = URL.createObjectURL(blob);
    //   a.target = '_blank';
    //   a.click();
    //   document.body.removeChild(a);
    //   if (response.status !== 200) {
    //     this.toastrDanger('Lỗi khi thực hiện tác vụ download.');
    //   }
    // });
  }

  b64DecodeUnicode(str: string) {
    return decodeURIComponent(
      atob(str)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );
  }

  toastrSuccess(message?: string, title?: string) {
    this.toastrService.success(
      `${message} ${SUCCESS_NOTICE}`,
      title ?? TITLE_NOTICE,
      { icon: '' }
    );
  }
  toastrPrimary(message: string, title?: string) {
    this.toastrService.primary(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrWarning(message: string, title?: string) {
    this.toastrService.warning(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrFailure(message?: string, title?: string) {
    this.toastrService.danger(
      `${FAILURE_NOTICE} vì ${message?.toLocaleLowerCase()}`,
      title ?? TITLE_NOTICE,
      { icon: '' }
    );
  }

  toastrInfo(message: string, title?: string) {
    this.toastrService.info(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrShow(message: string, title?: string) {
    this.toastrService.show(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  toastrDefault(message: string, title?: string) {
    this.toastrService.default(message, title ?? TITLE_NOTICE, { icon: '' });
  }

  configDialog(widthDialog: string, data: any): MatDialogConfig {
    const DIALOG_CONFIG = new MatDialogConfig();
    DIALOG_CONFIG.width = widthDialog;
    DIALOG_CONFIG.height = 'auto';
    DIALOG_CONFIG.maxWidth = WIDTH_DIALOG;
    DIALOG_CONFIG.maxHeight = HEIGHT_DIALOG;
    DIALOG_CONFIG.data = data;
    return DIALOG_CONFIG;
  }
}

@Component({
  template: '',
})
export class VitualScrollNgSelect {
  numberOfItemsFromEndBeforeFetchingMore = 10;
  loading = false;
  vitualData = [];
  lstVitualScroll = [];
  bufferSize = 10;

  constructor() {}

  setDataForVitualScroll(lst: any[]) {
    this.lstVitualScroll = this.vitualData.slice(0, this.bufferSize);
  }

  onScrollToEnd() {
    this.fetchMore();
  }

  onScroll(end: number) {
    if (this.loading || this.vitualData.length <= this.lstVitualScroll.length) {
      return;
    }

    if (
      end + this.numberOfItemsFromEndBeforeFetchingMore >=
      this.lstVitualScroll.length
    ) {
      this.fetchMore();
    }
  }
  private fetchMore() {
    const len = this.lstVitualScroll.length;
    const more = this.vitualData.slice(len, this.bufferSize + len);
    this.loading = true;
    // using timeout here to simulate backend API delay
    setTimeout(() => {
      this.loading = false;
      this.lstVitualScroll = this.lstVitualScroll.concat(more);
    }, 200);
  }
}

@Injectable({
  providedIn: 'root',
})
@Pipe({
  name: 'pipeDate',
})
export class ConvertDatePipe implements PipeTransform {
  //#region CONVERT_DATE

  transform(value: any) {
    const datePipe = new DatePipe('en-US');
    value = datePipe.transform(value, 'yyyy-MM-dd');
    return value;
  }

  convertDateToString(target: Date): string {
    if (target) {
      return this.transform(target.toString());
    }
    return '';
  }

  convertStringToDate(target: string) {
    const year = +target.substr(0, 4);
    const month = +target.substr(4, 2);
    const day = +target.substr(6, 2);
    return new Date(year, month, day);
  }

  convertStringddMMyyyyToFormatTextVN(target: string) {
    if (target && target.length === 8) {
      const year = target.substr(0, 4);
      const month = target.substr(4, 2);
      const day = target.substr(6, 2);
      return 'Ngày ' + day + ' tháng ' + month + ' năm ' + year;
    }
    return target;
  }
  //#endregion
}
