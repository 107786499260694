import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SysPhongBanIndexComponent } from './sysPhongBan-index/sysPhongBan-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: SysPhongBanIndexComponent }],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SysPhongBanRoutingModule {}
