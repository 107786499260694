import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { CommonService } from 'src/app/core/customs/common.service';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { QTHTApiService, UserModel } from 'src/app/core/services/qtht.service';

@Component({
  selector: 'ngx-user-info',
  templateUrl: './user-info.component.html',
  styleUrls: ['./user-info.component.scss'],
})
export class UserInfoComponent implements OnInit {
  constructor(
    private qthtService: QTHTApiService,
    private commonService: CommonService,
    private dialogRef: MatDialogRef<UserInfoComponent>,
    @Inject(MAT_DIALOG_DATA) private data: { data: any }
  ) {}

  target: any = new UserModel();
  uploadImage(event: any) {
    const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.target.image = reader.result;
    };
  }

  ngOnInit(): void {
    this.target = this.data.data;
  }
  save(frm: any) {
    this.qthtService
      .appQthtApiSysUserExtandUpdateInfo(this.target)
      .subscribe((rest) => {
        if (rest.code === SUCCESS_RESPONE) {
          this.commonService.toastrSuccess(rest.message);
          this.dialogRef.close(true);
        } else {
          this.commonService.toastrFailure(rest.message);
        }
      });
  }
}
