import { Component, OnInit } from '@angular/core';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { HomeService } from 'src/app/home/home.service';

@Component({
  selector: 'ngx-home-footer',
  templateUrl: './home-footer.component.html',
  styleUrls: ['./home-footer.component.scss'],
})
export class HomeFooterComponent implements OnInit {
  constructor(private service: HomeService) {}
  lstLoai: any = [];
  lstRecord: any = [];
  lstSanPham: any = [];
  year: number = new Date().getFullYear();
  ngOnInit(): void {
    this.service.selectAllLoaiSanPhamBaseHome().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.lstLoai = rest.data;
      }
    });
    // this.service.selectTop5SanPhamHome().subscribe((rest) => {
    //   if (rest.code === SUCCESS_RESPONE) {
    //     this.lstSanPham = rest.data;
    //   }
    // });
    this.service.selectTop5RecordHome().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.lstRecord = rest.data;
      }
    });
  }
}
