import { Component, OnInit, OnDestroy } from '@angular/core';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { HomeService } from 'src/app/home/home.service';
import 'bootstrap';

@Component({
  selector: 'ngx-home-header',
  styleUrls: ['./home-header.component.scss'],
  templateUrl: './home-header.component.html',
})
export class HomeHeaderComponent implements OnInit {
  constructor(private homeService: HomeService) {}
  lstDichVu: any = [];
  ngOnInit() {
    this.homeService.selectAllLoaiSanPhamBaseHome().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.lstDichVu = rest.data;
      }
    });
  }
}
