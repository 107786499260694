import { Component, OnInit } from '@angular/core';
import { CommonService } from 'src/app/core/customs/common.service';
import { MatDialog } from '@angular/material/dialog';
import {
  Action,
  LBL_DISPLAY,
  PAGE_SIZE,
  SUCCESS_RESPONE,
} from 'src/app/core/customs/constants';
import {
  PhongBanModel,
  PhongBanModelListPageModelView,
  SysPageModel,
} from 'src/app/core/services/qtht.service';
import { SysPhongBanService } from '../sysPhongBan.service';
import * as Excel from 'exceljs';
import * as fs from 'file-saver';
import * as XLSX from 'xlsx';
import { DialogConfirmComponent } from 'src/app/theme/components/template/button/dialog/dialog-confirm/dialog-confirm.component';
import { environment } from 'src/environments/environment';
import { SysPhongBanCrudComponent } from '../sysPhongBan-crud/sysPhongBan-crud.component';

@Component({
  selector: 'ngx-sysPhongBan-index',
  templateUrl: './sysPhongBan-index.component.html',
  styleUrls: ['./sysPhongBan-index.component.scss'],
})
export class SysPhongBanIndexComponent implements OnInit {
  constructor(
    private service: SysPhongBanService,
    private commonService: CommonService,
    private dialog: MatDialog
  ) {}
  title = 'Quản lý phòng ban';
  target: any = {
    data: [],
  };
  unit: any;
  pageModel: SysPageModel = new SysPageModel({
    search: '',
    currentPage: 1,
    pageSize: PAGE_SIZE,
    condition: '',
    sortColumn: [],
    searchColumn: [],
  });
  ngOnInit(): void {
    this.loadData();
    this.service.getUnitInfo().subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.unit = rest.data;
      }
    });
  }
  getSTT(index: number) {
    return (
      (this.pageModel.pageSize ?? 0) * ((this.pageModel.currentPage ?? 1) - 1) +
      index +
      1
    );
  }

  downLoadTemplate() {
    this.commonService.downloadFileTemPlate('SysPhongBan');
  }
  genTable(data: any) {
    let body = `<table class="table table-bordered table-hover" id="customers">
    <thead>
      <tr>
        <th class="text-center">STT</th>
        <th class="text-center">Tên phòng ban</th>
        <th class="text-center">Trạng thái</th>
        <th class="text-center">Ngày tạo</th>
        <th class="text-center">Ngày cập nhật</th>
      </tr>
    </thead>
    <tbody>`;
    if (data.length === 0) {
      body = `<tr><td colspan="3">Không có dữ liệu</td></tr>`;
    } else {
      data.forEach(
        (
          item: {
            tenPhongBan: string;
            trangThai: number;
            createDate: Date;
            updateDate: Date;
          },
          index: number
        ) => {
          body += '<tr>';
          body += `<td class="text-center">` + (index + 1).toString() + `</td>`;
          body += `<td class="text-left">` + item.tenPhongBan + `</td>`;
          body +=
            `<td class="text-center">` +
            (item.trangThai === 1 ? 'Hiển thị' : 'Không hiển thị') +
            `</td>`;
          body +=
            `<td class="text-right" style="text-align: right">` +
            item.createDate?.toLocaleDateString('en-GB') +
            `</td>`;
          body +=
            `<td class="text-right" style="text-align: right">` +
            item.updateDate?.toLocaleDateString('en-GB') +
            `</td>`;
          body += '</tr>';
        }
      );
    }
    body += `</tbody>
  </table>`;
    return body;
  }
  getHtml(data: any) {
    return this.commonService.generalHtml(
      this.title,
      this.genTable(data),
      this.unit?.unitName ?? ''
    );
  }
  printWord() {
    if (this.selectItem) {
      var count = this.lstSelectItem.length;
      if (count === 0) {
        this.dialog
          .open(
            DialogConfirmComponent,
            this.commonService.configDialog('40%', {
              title: LBL_DISPLAY.print,
              message: LBL_DISPLAY.confirmAll,
              type: 1,
            })
          )
          .afterClosed()
          .subscribe((rest) => {
            if (rest) {
              this.printAll();
            }
          });
      } else {
        this.dialog
          .open(
            DialogConfirmComponent,
            this.commonService.configDialog('40%', {
              title: LBL_DISPLAY.print,
              message: LBL_DISPLAY.confirmSelect,
              type: 1,
            })
          )
          .afterClosed()
          .subscribe((rest) => {
            if (rest) {
              this.dataToPrint(this.lstSelectItem);
            }
          });
      }
    } else {
      this.dialog
        .open(
          DialogConfirmComponent,
          this.commonService.configDialog('40%', {
            title: LBL_DISPLAY.print,
            message: LBL_DISPLAY.confirmAll,
            type: 1,
          })
        )
        .afterClosed()
        .subscribe((rest) => {
          if (rest) {
            this.printAll();
          }
        });
    }
  }
  dataToPrint(data: any) {
    const html = this.getHtml(data);
    let newWin = window.open(this.title);
    newWin?.document.write(html);
    newWin?.print();
    newWin?.close();
  }
  exportPdf() {
    if (this.selectItem) {
      var count = this.lstSelectItem.length;
      if (count === 0) {
        this.dialog
          .open(
            DialogConfirmComponent,
            this.commonService.configDialog('40%', {
              title: LBL_DISPLAY.exportPdf,
              message: LBL_DISPLAY.confirmAll,
              type: 1,
            })
          )
          .afterClosed()
          .subscribe((rest) => {
            if (rest) {
              this.pdfAll();
            }
          });
      } else {
        this.dialog
          .open(
            DialogConfirmComponent,
            this.commonService.configDialog('40%', {
              title: LBL_DISPLAY.exportPdf,
              message: LBL_DISPLAY.confirmSelect,
              type: 1,
            })
          )
          .afterClosed()
          .subscribe((rest) => {
            if (rest) {
              this.dataToPdf(this.lstSelectItem);
            }
          });
      }
    } else {
      this.dialog
        .open(
          DialogConfirmComponent,
          this.commonService.configDialog('40%', {
            title: LBL_DISPLAY.exportPdf,
            message: LBL_DISPLAY.confirmAll,
            type: 1,
          })
        )
        .afterClosed()
        .subscribe((rest) => {
          if (rest) {
            this.pdfAll();
          }
        });
    }
  }
  pdfAll() {
    this.service.selectAll().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.dataToPdf(ret.data);
      }
    });
  }
  dataToPdf(data: any) {
    // const htmlString = this.getHtml(data);
    // var div = document.createElement('div');
    // div.id = 'testing';
    // div.innerHTML += htmlString;
    // document.body.appendChild(div);
    //  (document.getElementById('testing') ?? new HTMLElement()).then(
    //   (canvas) => {
    //     var data = canvas.toDataURL();
    //     var docDefinition = {
    //       content: [
    //         {
    //           image: data,
    //           width: 520,
    //         },
    //       ],
    //     };
    //     pdfMake.createPdf(docDefinition).download(this.title + '.pdf');
    //     document.body.removeChild(div);
    //   }
    // );
  }

  importExcel(files: any) {
    if (
      files.target.files[0].type ===
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' ||
      files.target.files[0].type === 'application/vnd.ms-excel'
    ) {
      const targetfiles: DataTransfer = <DataTransfer>files.target;
      const reader: FileReader = new FileReader();
      reader.onload = (e: any) => {
        const bstr: string = e.target.result;
        const wb: XLSX.WorkBook = XLSX.read(bstr, { type: 'binary' });
        const wsname: string = wb.SheetNames[0];
        const ws: XLSX.WorkSheet = wb.Sheets[wsname];
        let dataExcel: [][] = [];
        dataExcel = XLSX.utils.sheet_to_json(ws, { header: 1 });
        let data = [];
        if (dataExcel[2]) {
          for (let i = 3; i < dataExcel.length; i++) {
            if (dataExcel[i].length > 0) {
              let item_ = new PhongBanModel();
              const item = dataExcel[i]?.map((item1: any) => item1);
              item_.tenPhongBan = item[1];
              if (!this.commonService.checkObjectNull(item_)) data.push(item_);
            }
          }
          this.dialog
            .open(
              SysPhongBanCrudComponent,
              this.commonService.configDialog('80%', {
                data: data,
                action: Action.Import,
                title: this.title,
              })
            )
            .afterClosed()
            .subscribe(() => {
              this.loadData();
            });
        }
      };
      reader.readAsBinaryString(targetfiles.files[0]);
    } else {
      this.commonService.toastrFailure('File import không đúng định dạng!');
    }
  }

  dataToExcel(data: any) {
    const workbook = new Excel.Workbook();
    let worksheet = workbook.addWorksheet('Dữ liệu', {
      pageSetup: { paperSize: Excel.PaperSize.A4 },
    });
    worksheet.columns = [
      {
        header: [this.title, '', 'STT'],
        key: 'stt',
        width: environment.with_export,
      },
      {
        header: [this.title, '', 'Tên phòng ban'],
        key: 'tenPhongBan',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Trạng thái'],
        key: 'trangThai',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Ngày tạo'],
        key: 'createDate',
        width: 3 * environment.with_export,
      },
      {
        header: [this.title, '', 'Ngày cập nhật'],
        key: 'updateDate',
        width: 3 * environment.with_export,
      },
    ];
    worksheet.mergeCells(1, 1, 1, worksheet.columns.length);
    worksheet.getCell(1, 1).style = {
      font: { bold: true, name: 'Times New Roman', size: 14 },
      alignment: { vertical: 'middle', horizontal: 'center', wrapText: true },
    };
    data?.forEach((e: any, index: number) => {
      worksheet.addRow(
        {
          stt: index + 1,
          tenPhongBan: e?.tenPhongBan,
          trangThai: e?.trangThai === 1 ? 'Hiển thị' : 'Không hiển thị',
          createDate: e?.createDate?.toLocaleDateString('en-GB'),
          updateDate: e?.updateDate?.toLocaleDateString('en-GB'),
        },
        'n'
      );
    });
    worksheet = this.commonService.setStyleExport(
      worksheet,
      1,
      worksheet.columns.length,
      3,
      3,
      [1, 3],
      [4, 5]
    );
    workbook.xlsx.writeBuffer().then((data) => {
      const blob = new Blob([data], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });
      fs.saveAs(blob, this.title + '.xlsx');
    });
  }
  exportExcel() {
    if (this.selectItem) {
      var count = this.lstSelectItem.length;
      if (count === 0) {
        this.dialog
          .open(
            DialogConfirmComponent,
            this.commonService.configDialog('40%', {
              title: LBL_DISPLAY.exportFile,
              message: LBL_DISPLAY.confirmAll,
              type: 1,
            })
          )
          .afterClosed()
          .subscribe((rest) => {
            if (rest) {
              this.exportAll();
            }
          });
      } else {
        this.dialog
          .open(
            DialogConfirmComponent,
            this.commonService.configDialog('40%', {
              title: LBL_DISPLAY.exportFile,
              message: LBL_DISPLAY.confirmSelect,
              type: 1,
            })
          )
          .afterClosed()
          .subscribe((rest) => {
            if (rest) {
              this.dataToExcel(this.lstSelectItem);
            }
          });
      }
    } else {
      this.dialog
        .open(
          DialogConfirmComponent,
          this.commonService.configDialog('40%', {
            title: LBL_DISPLAY.exportFile,
            message: LBL_DISPLAY.confirmAll,
            type: 1,
          })
        )
        .afterClosed()
        .subscribe((rest) => {
          if (rest) {
            this.exportAll();
          }
        });
    }
  }
  printAll() {
    this.service.selectAll().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.dataToPrint(ret.data);
      }
    });
  }
  exportAll() {
    this.service.selectAll().subscribe((ret) => {
      if (ret.code === SUCCESS_RESPONE) {
        this.dataToExcel(ret.data);
      }
    });
  }
  SetValueTagetData(data: any, value: boolean) {
    data.map((element: { [x: string]: boolean }) => {
      element['check'] = value;
      return element;
    });
  }
  loadData() {
    this.service.paging(this.pageModel).subscribe((res) => {
      if (res.code === SUCCESS_RESPONE) {
        this.target = res.data ?? new PhongBanModelListPageModelView();
        if (this.lstSelectItem.length === 0) {
          this.SetValueTagetData(this.target.data, false);
        } else {
          this.target.data.forEach(
            (element: { [x: string]: boolean; PhongBanId: any }) => {
              element['check'] =
                this.lstSelectItem.findIndex(
                  (e: { PhongBanId: any }) =>
                    e.PhongBanId === element.PhongBanId
                ) > -1;
            }
          );
        }
        this.checkAllCurrent();
      } else {
        this.commonService.toastrFailure(res.message ?? '');
      }
    });
  }
  selectAllCurrentPage() {
    if (this.checkAllPageCurrent) {
      this.SetValueTagetData(this.target.data, true);
      var tmp = this.target.data.filter(
        (c: any) => this.lstSelectItem.indexOf(c) < 0
      );
      this.lstSelectItem = this.lstSelectItem.concat(tmp);
    } else {
      this.SetValueTagetData(this.target.data, false);
      this.lstSelectItem = this.lstSelectItem.filter(
        (c) => this.target.data.indexOf(c) < -1
      );
    }
  }
  searchNangCao: boolean = false;
  sapXepNangCao: boolean = false;
  selectItem: boolean = false;
  checkAllPageCurrent: boolean = false;
  lstSelectItem: any[] = [];
  checkAllCurrent() {
    this.checkAllPageCurrent =
      this.target.data.length > 0 &&
      this.lstSelectItem.filter(
        (c) =>
          this.target.data.findIndex(
            (e: { PhongBanId: any }) => e.PhongBanId === c.PhongBanId
          ) > -1
      )?.length === this.target.data.length;
  }

  Selectitem(_event: Event, item: any) {
    item['check'] = item['check'] === true ? false : true;
    if (item.check) {
      this.lstSelectItem.push(item);
      this.checkAllCurrent();
    } else {
      this.lstSelectItem = this.lstSelectItem.filter(
        (c) => c.PhongBanId !== item.PhongBanId
      );
      this.checkAllPageCurrent = false;
    }
  }
  changeSearchNangCao() {
    this.searchNangCao = !this.searchNangCao;
    return this.searchNangCao;
  }

  changeSapXepNangCao() {
    this.sapXepNangCao = !this.sapXepNangCao;
    return this.sapXepNangCao;
  }

  changeSelectItem() {
    this.selectItem = !this.selectItem;
    return this.selectItem;
  }

  searchPage() {
    this.pageModel.currentPage = 1;
    this.loadData();
  }

  resetPage() {
    this.pageModel.search = '';
    this.pageModel.searchColumn = [];
    this.pageModel.sortColumn = [];
    this.loadData();
  }

  create() {
    this.dialog
      .open(
        SysPhongBanCrudComponent,
        this.commonService.configDialog('80%', {
          data: [new PhongBanModel()],
          action: Action.Create,
          title: this.title,
        })
      )
      .afterClosed()
      .subscribe(() => {
        this.loadData();
      });
  }

  viewDetail(item: any) {
    this.dialog
      .open(
        SysPhongBanCrudComponent,
        this.commonService.configDialog('80%', {
          data: [item],
          action: Action.Detail,
          title: this.title,
        })
      )
      .afterClosed()
      .subscribe(() => {});
  }

  viewUpdate(item: any) {
    this.dialog
      .open(
        SysPhongBanCrudComponent,
        this.commonService.configDialog('80%', {
          data: [item],
          action: Action.Update,
          title: this.title,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) this.loadData();
      });
  }
  viewDelete(_item: any) {
    this.dialog
      .open(
        DialogConfirmComponent,
        this.commonService.configDialog('40%', {
          title: LBL_DISPLAY.delete,
          message: LBL_DISPLAY.confirmDelete,
          type: 1,
        })
      )
      .afterClosed()
      .subscribe((rest) => {
        if (rest) {
          this.service.delete(_item).subscribe((rest) => {
            if (rest.code === SUCCESS_RESPONE) {
              this.commonService.toastrSuccess(rest.message);
              this.loadData();
            } else {
              this.commonService.toastrFailure(rest.message);
            }
          });
        }
      });
  }

  changeStatus(item: any) {
    if (item.trangThai) item.trangThai = 1;
    else item.trangThai = 0;
    this.service.updateTrangThai(item).subscribe((rest) => {
      if (rest.code === SUCCESS_RESPONE) {
        this.commonService.toastrSuccess(rest.message);
      } else {
        this.commonService.toastrFailure(rest.message);
      }
    });
  }
}
