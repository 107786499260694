<ul class="pagination">
  <li class="page-item">
    <a
      *ngIf="totalPages > 0"
      class="page-link page-link-customs"
      (click)="pageFirst()"
      >Đầu trang</a
    >
  </li>
  <li class="page-item">
    <a
      class="page-link page-link-customs"
      (click)="previous()"
      *ngIf="currentPage > 1"
      >Trang trước</a
    >
  </li>
  <li
    class="ngb-custom-pages-item"
    style="border-right: 1px solid #dddddd"
    *ngIf="totalPages > 0"
  >
    <div class="d-flex flex-nowrap px-2">
      <label
        id="paginationInputLabel"
        for="paginationInput"
        class="col-form-label mr-2 ml-1"
        >Trang</label
      >
      <input
        type="text"
        inputmode="numeric"
        pattern="[0-9]*"
        id="paginationInput"
        [(ngModel)]="currentPage"
        aria-labelledby="paginationInputLabel paginationDescription"
        class="form-control custom-pages-input"
        style="width: 5rem"
        (change)="changeNumberPage()"
        (keyup.enter)="changeNumberPage()"
      />
      <span id="paginationDescription" class="col-form-label text-nowrap px-2">
        trong {{ totalPages }}</span
      >
    </div>
  </li>
  <li class="page-item">
    <a
      class="page-link page-link-customs"
      (click)="next()"
      >Trang sau</a
    >
  </li>
  <li class="page-item" *ngIf="totalPages > 0">
    <a class="page-link page-link-customs" (click)="pageLast()">Cuối trang</a>
  </li>
</ul>
