import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CommonService } from 'src/app/core/customs/common.service';
import { SUCCESS_RESPONE } from 'src/app/core/customs/constants';
import { AuthService } from 'src/app/core/services/auth.service';
import {
  QTHTApiService,
  UserModelBasic,
} from 'src/app/core/services/qtht.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'ngx-login-layout',
  templateUrl: './login-layout.component.html',
  styleUrls: ['login-layout.component.scss'],
})
export class LoginLayoutComponent implements OnInit {
  lstApplication: any[] = [];

  constructor(
    private router: Router,
    private authService: AuthService,
    private route: ActivatedRoute,
    private qthtservice: QTHTApiService
  ) {}
  user = new UserModelBasic();
  year = new Date().getFullYear();
  ngOnInit() {
    this.route.paramMap.subscribe((params: { get: (arg0: string) => any }) => {
      var token = params.get('token');
      if (token) {
        this.authService
          .refreshTokenSSO(decodeURIComponent(token))
          .then(() => {});
      }
      if (!this.authService.checkTokenExpired()) {
        this.router.navigate(['/qtht']).then(
          (nav) => {},
          (err) => {
            this.authService.logout();
          }
        );
      }
      this.isLogin = true;
    });
  }
  isLogin: boolean = true;
  checkLogin() {
    if (this.user.userName && this.user.password && this.isLogin) {
      return true;
    }
    return false;
  }
  login() {
    setInterval(() => {
      this.isLogin = !this.isLogin;
    }, environment.timeWaitingLoginFailure);
    this.authService.login(this.user).subscribe(() => {});
    this.isLogin = !this.isLogin;
  }
}
