import { Injectable } from '@angular/core';
import { NbMenuItem } from '@nebular/theme';
import { BehaviorSubject, Observable } from 'rxjs';
import {
  MenuModelListApiResponse,
  QTHTApiService,
} from 'src/app/core/services/qtht.service';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  constructor(private qthtService: QTHTApiService) {
    this.menuItemsChangedCallback = new BehaviorSubject<NbMenuItem[]>([]);
    this.routingChangedCallback = new BehaviorSubject<
      MenuModelListApiResponse[]
    >([]);
  }
  private menuItemsChangedCallback: BehaviorSubject<NbMenuItem[]>;
  private routingChangedCallback: BehaviorSubject<MenuModelListApiResponse[]>;

  getNbMenuItem(): Observable<NbMenuItem[]> {
    return this.menuItemsChangedCallback;
  }

  setNbMenuItem(lstMenu: NbMenuItem[]) {
    this.menuItemsChangedCallback.next(lstMenu);
  }

  getRoutingSaveCheckRole(): BehaviorSubject<any[]> {
    return this.routingChangedCallback;
  }

  setRoutingSaveCheckRole(lst: any[]) {
    this.routingChangedCallback.next(lst);
  }

  getMenuByUserLogin(): Observable<MenuModelListApiResponse> {
    return this.qthtService.appQthtApiMenuExtandGetModelByUserLogin();
  }
  resetMenuWhenLogin() {
    this.menuItemsChangedCallback = new BehaviorSubject<NbMenuItem[]>([]);
  }
}
