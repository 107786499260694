import {
  Component,
  ViewEncapsulation,
  Input,
  Output,
  EventEmitter,
  OnInit,
} from '@angular/core';
import { PAGE_SIZE } from 'src/app/core/customs/constants';

@Component({
  selector: 'ngx-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.scss'],
  encapsulation: ViewEncapsulation.Emulated,
})
export class MdPaginationComponent implements OnInit {
  @Input() totalPages: number = 0;
  @Input() currentPage: number = 1;
  @Output() pageChange: EventEmitter<any> = new EventEmitter<any>();
  @Output() pageSize: EventEmitter<number> = new EventEmitter<number>();

  selectRecordInPage: number = PAGE_SIZE;
  activePage: number = 1;
  pageNumber: number = 1;

  lstRecordInPage: any[] = [
    { Value: 10, Text: '10 dòng' },
    { Value: 15, Text: '15 dòng' },
    { Value: 20, Text: '20 dòng' },
    { Value: 25, Text: '25 dòng' },
  ];
  constructor() {}

  ngOnInit(): void {
    this.activePage = this.currentPage;
  }

  previous() {
    if (this.activePage > 1) {
      this.activePage--;
      this.changeTotalItems();
    }
  }
  next() {
    if (this.activePage < this.totalPages || this.totalPages == 0) {
      this.activePage++;
      this.changeTotalItems();
    }
  }

  pageFirst() {
    if (this.activePage !== 1) {
      this.activePage = 1;
      this.changeTotalItems();
    }
  }

  pageLast() {
    if (this.activePage !== this.totalPages) {
      this.activePage = this.totalPages;
      this.changeTotalItems();
    }
  }

  changeNumberPage() {
    if (
      this.currentPage !== null &&
      this.currentPage !== undefined &&
      this.currentPage > 0 &&
      this.currentPage < this.totalPages
    ) {
      this.activePage = this.currentPage;
      this.changeTotalItems();
    }
  }

  changeTotalItems() {
    return this.pageChange.emit(this.activePage);
  }
  changePerPage() {
    this.activePage = 1;
    return this.pageSize.emit(this.selectRecordInPage);
  }
}
