import { Component, Input, OnInit } from '@angular/core';
import { LBL_DISPLAY } from 'src/app/core/customs/constants';

@Component({
  selector: 'ngx-btn-export-file',
  templateUrl: './btn-export-file.component.html',
  styleUrls: ['./btn-export-file.component.scss'],
})
export class BtnExportFileComponent implements OnInit {
  @Input() disabled: boolean | undefined;
  @Input() color: string | undefined;
  @Input() text: string | undefined;
  constructor() {}

  ngOnInit(): void {
    this.text = this.text ?? LBL_DISPLAY.exportFile;
    this.disabled = this.disabled ?? false;
    this.color = this.color ?? 'secondary';
  }
}
