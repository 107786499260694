import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import {
  NbDialogModule,
  NbMenuModule,
  NbSidebarModule,
  NbToastrModule,
  NbWindowModule,
} from '@nebular/theme';
import { QTHTRoutingModule } from './qtht-routing.module';
import { CookieModule } from 'ngx-cookie';
import { SysChucDanhModule } from './sysChucDanh/sysChucDanh.module';
import { SysLoaiSanPhamModule } from './sysLoaiSanPham/sysLoaiSanPham.module';
import { SysPhongBanModule } from './sysPhongBan/sysPhongBan.module';
import { SysRecordModule } from './sysRecord/sysRecord.module';
import { SysUserModule } from './sysUser/sysUser.module';
import { SysDanhGiaModule } from './sysDanhGia/sysDanhGia.module';
@NgModule({
  imports: [
    QTHTRoutingModule,
    SysUserModule,
    SysRecordModule,
    SysPhongBanModule,
    SysChucDanhModule,
    SysLoaiSanPhamModule,
    SysDanhGiaModule,
    NbSidebarModule.forRoot(),
    NbMenuModule.forRoot(),
    NbDialogModule.forRoot(),
    NbWindowModule.forRoot(),
    NbToastrModule.forRoot(),
    CookieModule.forRoot(),
  ],
  providers: [],
  declarations: [],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class QTHTModule {}
