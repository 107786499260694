<nb-card>
  <nb-card-header>
    <div class="row">
      <div class="col-sm-10 col-md-10 col-xs-10 col-lg-10">
        <p>
          <span>{{ title }} {{ phongBan?.tenPhongBan }}</span>
        </p>
      </div>
      <div class="col text-right">
        <i
          class="fa"
          [ngClass]="{
            'fa-eye': hiden,
            'fa-eye-slash': !hiden
          }"
          aria-hidden="true"
          (click)="changeSee()"
        ></i>
      </div>
    </div>
  </nb-card-header>
  <nb-card-body *ngIf="hiden">
    <div class="row">
      <div class="col-10 align-self-center">
        <input
          type="text"
          class="form-control"
          placeholder="Nhập nội dung tìm kiếm"
          [(ngModel)]="pageModel.search"
          (keyup.enter)="loadData()"
          name="search"
          #search="ngModel"
        />
      </div>
      <div class="col-2">
        <ngx-btn-search (click)="searchPage()"></ngx-btn-search>
      </div>
      &nbsp;
    </div>
    <div class="row" id="tablePrint">
      <div class="col-md-12 col-sm-12 col-xs-12 col-lg-12">
        <table class="table table-bordered table-hover">
          <thead>
            <tr>
              <th class="text-center">STT</th>
              <th class="text-center">Chức danh</th>
              <th class="text-center">Trạng thái</th>
              <th class="text-center">Ngày tạo</th>
              <th class="text-center">Ngày cập nhật</th>
              <th class="text-center">Thao tác</th>
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let item of target.data; let i = index">
              <td class="text-center">
                {{ getSTT(i) }}
              </td>
              <td class="text-left">
                {{ item.tenChucDanh }}
              </td>
              <td class="text-center">
                <mat-slide-toggle
                  [color]="'primary'"
                  [(ngModel)]="item.trangThai"
                  [disabled]="false"
                  (change)="changeStatus(item)"
                >
                </mat-slide-toggle>
              </td>
              <td class="text-right" style="text-align: right">
                {{ item.createDate?.toLocaleDateString("en-GB") }}
              </td>
              <td class="text-right" style="text-align: right">
                {{ item.updateDate?.toLocaleDateString("en-GB") }}
              </td>
              <td class="text-center">
                <ngx-icon-info (click)="viewDetail(item)"></ngx-icon-info
                >&nbsp;&nbsp;
                <ngx-icon-edit (click)="viewUpdate(item)"></ngx-icon-edit
                >&nbsp;&nbsp;
                <ngx-icon-delete (click)="viewDelete(item)"></ngx-icon-delete>
              </td>
            </tr>
            <tr *ngIf="target.data?.length === 0">
              <td colspan="3">Không có dữ liệu</td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>

    <div class="row" style="float: right">
      <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
        <ngx-pagination
          (pageChange)="pageModel.currentPage = $event; loadData()"
          (pageSize)="pageModel.pageSize = $event; loadData()"
          [currentPage]="pageModel.currentPage ?? 1"
          [totalPages]="target.totalPage ?? 0"
        >
        </ngx-pagination>
      </div>
    </div>
  </nb-card-body>
</nb-card>
