import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  RecordModelApiResponse,
  SysPageModel,
  VCVNApiService,
  DanhGiaModel,
  DanhGiaModelListApiResponse,
  Int32ApiResponse,
  LoaiSanPhamModelListApiResponse,
  RecordModelListApiResponse,
  RecordModelListPageModelViewApiResponse,
  StringApiResponse,
  RegisterServiceModel,
  ScheduleServiceModel,
} from '../core/services/vcvn.service';
@Injectable({
  providedIn: 'root',
})
export class HomeService {
  constructor(private api: VCVNApiService) {}

  //home-footer
  selectAllLoaiSanPhamBaseHome(): Observable<LoaiSanPhamModelListApiResponse> {
    return this.api.appVCVNApiHomeSelectAllLoaiSanPhamBaseHome();
  }
  selectTop5RecordHome(): Observable<RecordModelListApiResponse> {
    return this.api.appVCVNApiHomeSelectTop5RecordHome();
  }
  registerServiceHome(
    body: RegisterServiceModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiHomeRegisterServiceHome(body);
  }
  scheduleSerServiceHome(
    body: ScheduleServiceModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiHomeScheduleSerServiceHome(body);
  }
  //end
  //home index
  selectTopDanhGiaHome(
    body: SysPageModel | undefined
  ): Observable<DanhGiaModelListApiResponse> {
    return this.api.appVCVNApiHomeSelectTopDanhGiaHome(body);
  }
  getImageDanhGiaHome(danhGiaId: number): Observable<StringApiResponse> {
    return this.api.appVCVNApiHomeGetImageDanhGiaHome(danhGiaId);
  }
  createDanhGiaFromHome(
    body: DanhGiaModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiHomeCreateDanhGiaFromHome(body);
  }
  pagingRecordHome(
    body: SysPageModel | undefined
  ): Observable<RecordModelListPageModelViewApiResponse> {
    return this.api.appVCVNApiHomePagingRecordHome(body);
  }
  getImageRecordHome(path: string): Observable<StringApiResponse> {
    return this.api.appVCVNApiHomeGetImageRecordHome(path);
  }
  selectOneRecordHome(path: string): Observable<RecordModelApiResponse> {
    return this.api.appVCVNApiHomeSelectOneRecordHome(path);
  }
  updateNumberWatchRecordHome(
    body: string | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiHomeUpdateNumberWatchRecordHome(body);
  }
  getNoiDungRecordHome(path: string): Observable<StringApiResponse> {
    return this.api.appVCVNApiHomeGetNoiDungRecordHome(path);
  }
  selectRecordByLoaiHome(
    body: SysPageModel | undefined
  ): Observable<RecordModelListApiResponse> {
    return this.api.appVCVNApiHomeSelectRecordByChuDeHome(body);
  }
  selectOneRecordHomeBy(path: string): Observable<RecordModelApiResponse> {
    return this.api.appVCVNApiHomeSelectOneRecordHomeBy(path);
  }
}
