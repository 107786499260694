import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { SysChucDanhIndexComponent } from './sysChucDanh-index/sysChucDanh-index.component';
import { SysChucDanhCrudComponent } from './sysChucDanh-crud/sysChucDanh-crud.component';
import { SysChucDanhDetailComponent } from './sysChucDanh-detail/sysChucDanh-detail.component';
import { NgxSpinnerModule } from 'ngx-spinner';

@NgModule({
  declarations: [
    SysChucDanhIndexComponent,
    SysChucDanhCrudComponent,
    SysChucDanhDetailComponent,
  ],
  imports: [FormsModule, ThemeModule, MatSlideToggleModule, NgxSpinnerModule],
})
export class SysChucDanhModule {}
