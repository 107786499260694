import { RouterModule, Routes } from '@angular/router';
import { NgModule } from '@angular/core';
import { SysRecordIndexComponent } from './sysRecord-index/sysRecord-index.component';

export const routes: Routes = [
  {
    path: '',
    children: [{ path: '', component: SysRecordIndexComponent }],
  },
];
@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class SysRecordRoutingModule {}
