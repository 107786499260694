import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { ThemeModule } from 'src/app/theme/theme.module';
import { SysDanhGiaIndexComponent } from './sysDanhGia-index/sysDanhGia-index.component';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSelectModule } from '@angular/material/select';
import { SysDanhGiaCrudComponent } from './sysDanhGia-crud/sysDanhGia-crud.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NbTabsetModule } from '@nebular/theme';

@NgModule({
  declarations: [SysDanhGiaIndexComponent, SysDanhGiaCrudComponent],
  imports: [
    FormsModule,
    ThemeModule,
    MatSelectModule,
    MatSlideToggleModule,
    NgxSpinnerModule,
    NgbModule,
    NbTabsetModule,
  ],
})
export class SysDanhGiaModule {}
