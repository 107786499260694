<form #frm="ngForm" (ngSubmit)="update(frm)">
  <nb-card *ngFor="let item of target.data; let i = index">
    <nb-card-header>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12">
          <p>
            <span>{{ getTitle() }}</span>
          </p>
        </div>
      </div>
    </nb-card-header>
    <nb-card-body style="max-height: 60vh; overflow: auto">
      <div class="row">
        <div class="col align-self-center">
          <label for="ten" class="required">Tên </label>
        </div>
        <div class="col-10">
          <input
            type="text"
            class="form-control"
            [(ngModel)]="item.ten"
            name="ten"
            maxlength="500"
            #ten="ngModel"
            placeholder="Tên khách hàng"
            id="ten"
            required
            [disabled]="isDetail()"
          />
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col align-self-center">
          <label for="ten" class="required">SDT </label>
        </div>
        <div class="col-10">
          <input
            autocomplete="false"
            type="text"
            class="form-control"
            name="item.sdt{{ i }}"
            maxlength="50"
            [(ngModel)]="item.sdt"
            required
            [disabled]="isDetail()"
            [pattern]="'(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})'"
            #sdt="ngModel"
          />
        </div>
      </div>
      &nbsp;
      <div class="row" *ngIf="target.menuName === 'sanpham'">
        <div class="col align-self-center">
          <label for="lstLoaiSanPham" class="required">Sản phẩm</label>
        </div>
        <div class="col-10">
          <ng-select
            name="lstLoaiSanPham{{ i }}"
            [items]="target.lstSanPham"
            bindValue="sanPhamId"
            bindLabel="tenSanPham"
            placeholder="Sản phẩm"
            [multiple]="false"
            [closeOnSelect]="true"
            [(ngModel)]="item.modelId"
            [clearOnBackspace]="false"
            appendTo="body"
            [clearSearchOnAdd]="true"
            [disabled]="isDetail()"
          >
          </ng-select>
        </div>
      </div>
      <div class="row" *ngIf="target.menuName === 'record'">
        <div class="col align-self-center">
          <label for="lstLoaiSanPham" class="required">Bài viết</label>
        </div>
        <div class="col-10">
          <ng-select
            name="lstRecord{{ i }}"
            [items]="target.lstRecord"
            bindValue="recordId"
            bindLabel="tieuDe"
            placeholder="Tiêu đề bài viết"
            [multiple]="false"
            [closeOnSelect]="true"
            [(ngModel)]="item.modelId"
            [clearOnBackspace]="false"
            appendTo="body"
            [clearSearchOnAdd]="true"
            [disabled]="isDetail()"
          >
          </ng-select>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col align-self-center">
          <label class="required">Ảnh</label>
        </div>
        <div class="col-3">
          <ngx-btn-file
            (change)="uploadImage($event, item)"
            [accept]="'image/*'"
            [multiple]="false"
            *ngIf="!isDetail()"
          ></ngx-btn-file>
        </div>
        <div class="col-7">
          <img src="{{ item.image }}" style="height: 10rem" />
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col align-self-center">
          <label for="nhanXet" class="required">Đánh giá</label>
        </div>
        <div class="col-10">
          <ngb-rating [(rate)]="item.rate" [max]="5" [readonly]="isDetail()">
          </ngb-rating>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col align-self-center">
          <label for="nhanXet" class="required">Nhận xét</label>
        </div>
      </div>
      &nbsp;
      <div class="row">
        <div class="col">
          <textarea
            autocomplete="false"
            type="text"
            class="form-control"
            name="item.nhanXet{{ i }}"
            [(ngModel)]="item.nhanXet"
            required
            [disabled]="isDetail()"
            #nhanXet="ngModel"
          >
          </textarea>
        </div>
      </div>
      &nbsp;
    </nb-card-body>
    <nb-card-footer>
      <div class="row">
        <div class="col-sm-12 col-md-12 col-xs-12 col-lg-12 text-right">
          <ngx-btn-submit
            *ngIf="!isDetail()"
            [disabled]="frm.invalid === true"
          ></ngx-btn-submit>
          &nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
        </div>
      </div>
    </nb-card-footer>
    &nbsp;
  </nb-card>
</form>
