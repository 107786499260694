<!-- Site Footer-->
<footer class="site-footer">
  <div class="container">
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <!-- Contact Info-->
        <section class="widget widget-light-skin">
          <h3 class="widget-title">Thông tin liên hệ</h3>
          <p class="text-white" title="Địa chỉ Vận chuyển Việt Nam">
            Địa chỉ: S302 VinHomes Smart City Tây Mỗ, Nam Từ Liêm Hà Nội
          </p>
          <p>
            <a
              class="navi-link-light"
              href="tel:0339919564"
              title="Hotline Vận chuyển Việt Nam"
            >
              Hotline: 0339919564
            </a>
          </p>
          <p>
            <a
              class="navi-link-light"
              href="https://www.facebook.com/vanchuyenvietnam2023"
              title="Fanpage Vận chuyển Việt Nam"
            >
              Fanpage: Vận chuyển Việt Nam
            </a>
          </p>
          <p>
            <a
              class="navi-link-light"
              href="mailto:vanchuyenvietnam2023@gmail.com"
              title="Email Vận chuyển Việt Nam"
            >
              Email: vanchuyenvietnam2023@gmail.com
            </a>
          </p>
        </section>
      </div>
      <div class="col-lg-3 col-md-6">
        <!-- Mobile App Buttons-->
        <section class="widget widget-links widget-light-skin">
          <h3 class="widget-title">Dịch vụ</h3>
          <ul>
            <li *ngFor="let item of lstLoai; let index">
              <a
                class="navi-link-light"
                href="/dich-vu/{{ item.path }}"
                title="{{ item.tenLoaiSanPham }} - Dịch vụ của chúng tôi"
              >
                {{ item.tenLoaiSanPham }}
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div class="col-lg-3 col-md-6">
        <!-- About Us-->
        <section class="widget widget-links widget-light-skin">
          <h3 class="widget-title">Tin tức</h3>
          <ul>
            <li *ngFor="let item of lstRecord; let index">
              <a
                class="navi-link-light"
                href="/tin-tuc/{{ item.path }}"
                title="{{ item.tieuDe }}"
              >
                {{ item.tieuDe }}
              </a>
            </li>
          </ul>
        </section>
      </div>
      <div class="col-lg-3 col-md-6">
        <!-- Account / Shipping Info-->
        <section class="widget widget-links widget-light-skin">
          <h3 class="widget-title">Chỉ đường</h3>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.7896404645962!2d105.73625481102435!3d21.001067838648034!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313453b8733ab939%3A0xefb38e754602e45a!2sT%C3%B2a%20S3.02%20Vinhomes%20Smart%20City!5e0!3m2!1svi!2s!4v1699368748584!5m2!1svi!2s"
            style="border: 0"
            loading="lazy"
            title="Vị trí Vận chuyển Việt Nam"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </section>
      </div>
    </div>
    <hr class="hr-light mt-2 margin-bottom-2x" />
    <!-- Copyright-->
    <p class="footer-copyright text-right">
      <a href="/login" title="Trang quản trị của chúng tôi"
        >Dành cho quản trị</a
      >
    </p>
    <p class="footer-copyright text-center">
      <a
        href="https://qtsoftvn.com/"
        target="_blank"
        title="QT VIET NAM TRANSPORTATION AND SOLUTION TECHNOLOGY"
      >
        © QT VIET NAM TRANSPORTATION AND SOLUTION TECHNOLOGY {{ year }}
      </a>
    </p>
  </div>
</footer>
