<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="qtht" class="sidebar-toggle">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="/qtht"><span>Quản trị hệ thống</span></a>
  </div>
</div>

<div class="header-container">
  <nb-select
    [selected]="currentTheme"
    (selectedChange)="changeTheme($event)"
    status="primary"
  >
    <nb-option *ngFor="let theme of themes" [value]="theme.value">
      {{ theme.name }}</nb-option
    >
  </nb-select>
  <nb-actions size="small">
    <nb-action class="user-action">
      <nb-user
        [nbContextMenu]="userMenu"
        [onlyPicture]="userPictureOnly"
        [name]="user?.fullName"
        [picture]="user.image"
        (click)="selectToolbar()"
      >
      </nb-user>
    </nb-action>
  </nb-actions>
</div>
