<form
  #frm="ngForm"
  (ngSubmit)="save(frm)"
  style="min-width: 400px; overflow: auto"
>
  <div class="card text-center">
    <div
      class="card-header"
      style="background-color: rgb(109, 153, 21); font-size: large"
    >
      <div class="text-center">Thông tin tài khoản</div>
    </div>
    <div class="card-body">
      <div class="form-group">
        <div class="row">
          <div class="col-3 align-self-center text-left">
            <label for="fullName">Tên đầy đủ</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="target.fullName"
              name="fullName"
              maxlength="50"
              #fullName="ngModel"
              placeholder="Tên đầy đủ"
              id="fullName"
            />
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-3 align-self-center text-left">
            <label for="userName">Tên đăng nhập</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="target.userName"
              name="userName"
              maxlength="50"
              #userName="ngModel"
              [readOnly]="'true'"
              id="userName"
              placeholder="Tên đăng nhập"
            />
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-3 align-self-center text-left">
            <label for="sdt">SDT</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="target.sdt"
              name="sdt"
              maxlength="12"
              #sdt="ngModel"
              id="sdt"
              [pattern]="'(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})'"
              placeholder="Số điện thoại đăng nhập"
            />
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-3 align-self-center text-left">
            <label for="email">Email</label>
          </div>
          <div class="col">
            <input
              type="text"
              class="form-control"
              [(ngModel)]="target.email"
              name="email"
              maxlength="50"
              #ten="ngModel"
              id="email"
              [pattern]="'[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'"
              placeholder="Email đăng nhập"
            />
          </div>
        </div>
        &nbsp;
        <div class="row">
          <div class="col-3 align-self-center text-left">
            <label>Ảnh đại diện</label>
          </div>
          <div class="col-3">
            <ngx-btn-file
              (change)="uploadImage($event)"
              [accept]="'image/*'"
              [multiple]="false"
            ></ngx-btn-file>
          </div>
          <div class="col-6">
            <img src="{{ target.image }}" width="90px" height="auto" />
          </div>
        </div>
        &nbsp;
      </div>
      &nbsp;
    </div>
    <div class="card-footer text-center">
      <div class="row justify-content-end">
        <div class="col text-right">
          <ngx-btn-save
            [disabled]="frm.invalid === true"
            [text]="'Cập nhật thông tin'"
            (click)="save(frm)"
          ></ngx-btn-save>
          &nbsp;
          <ngx-btn-close mat-dialog-close></ngx-btn-close>
          &nbsp;
        </div>
      </div>
    </div>
  </div>
</form>
