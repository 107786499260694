<ngx-spinner size="large" type="ball-scale-multiple"></ngx-spinner>
<nb-card-header>
  <h1>{{ title }}</h1>
</nb-card-header>
<nb-card-body>
  <div class="input-group">
    <ngx-btn-download-template
      (click)="downLoadTemplate()"
    ></ngx-btn-download-template>
    <ngx-btn-import-file
      (change)="importExcel($event)"
      [multiple]="false"
    ></ngx-btn-import-file>
    <ngx-btn-export-file (click)="exportExcel()"></ngx-btn-export-file>
    <ngx-btn-export-pdf (click)="exportPdf()"></ngx-btn-export-pdf>
    <ngx-btn-print (click)="printWord()"></ngx-btn-print>
  </div>
  &nbsp;
  <input
    type="text"
    class="form-control"
    placeholder="Nhập nội dung tìm kiếm"
    [(ngModel)]="pageModel.search"
    (keyup.enter)="loadData()"
    name="search"
    #search="ngModel"
  />
  <div class="row">
    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 text-left">
      <ngx-btn-add (click)="create()" text="Thêm mới"></ngx-btn-add>
    </div>
    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6 text-right">
      <ngx-btn-search (click)="searchPage()"></ngx-btn-search>
      <ngx-btn-refresh (click)="resetPage()"></ngx-btn-refresh>
    </div>
  </div>
  <div id="tablePrint">
    <table class="table table-bordered table-hover">
      <thead>
        <tr>
          <th class="text-center">STT</th>
          <th class="text-center">Chủ đề</th>
          <th class="text-center">Tiêu đề</th>
          <th class="text-center">Trạng thái</th>
          <th class="text-center">Ngày tạo</th>
          <th class="text-center">Ngày cập nhật</th>
          <th class="text-center">Thao tác</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of target.data; let i = index">
          <td class="text-center">{{ getSTT(i) }}</td>
          <td class="text-left">{{ getTenChuDe(item.chuDe) }}</td>
          <td class="text-left">{{ item.tieuDe }}</td>
          <td class="text-center">
            <mat-slide-toggle
              [color]="'primary'"
              [(ngModel)]="item.trangThai"
              [disabled]="false"
              (change)="changeStatus(item)"
            ></mat-slide-toggle>
          </td>
          <td class="text-right" style="text-align: right">
            {{ item.createDate?.toLocaleDateString("en-GB") }}
          </td>
          <td class="text-right" style="text-align: right">
            {{ item.updateDate?.toLocaleDateString("en-GB") }}
          </td>
          <td class="text-center">
            <ngx-icon-info (click)="viewDetail(item)"></ngx-icon-info
            >&nbsp;&nbsp;
            <ngx-icon-edit (click)="viewUpdate(item)"></ngx-icon-edit
            >&nbsp;&nbsp;
            <ngx-icon-delete (click)="viewDelete(item)"></ngx-icon-delete>
          </td>
        </tr>
        <tr *ngIf="target.data?.length === 0">
          <td colspan="3">Không có dữ liệu</td>
        </tr>
      </tbody>
    </table>
  </div>
  <div class="row" style="float: right">
    <div class="col-md-6 col-sm-6 col-xs-6 col-lg-6">
      <ngx-pagination
        (pageChange)="pageModel.currentPage = $event; loadData()"
        (pageSize)="pageModel.pageSize = $event; loadData()"
        [currentPage]="pageModel.currentPage ?? 1"
        [totalPages]="target.totalPage ?? 0"
      ></ngx-pagination>
    </div>
  </div>
</nb-card-body>
