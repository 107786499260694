import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import {
  Int32ApiResponse,
  DanhGiaModel,
  DanhGiaModelListApiResponse,
  DanhGiaModelListPageModelViewApiResponse,
  ResultImportModelApiResponse,
  SysPageModel,
  VCVNApiService,
  DanhGiaModelApiResponse,
} from 'src/app/core/services/vcvn.service';
import {
  QTHTApiService,
  StringApiResponse,
} from 'src/app/core/services/qtht.service';
@Injectable({
  providedIn: 'root',
})
export class SysDanhGiaService {
  constructor(private api: VCVNApiService, private apiQTHT: QTHTApiService) {}

  paging(
    body: SysPageModel
  ): Observable<DanhGiaModelListPageModelViewApiResponse> {
    return this.api.appVCVNApiDanhGiaPaging(body);
  }
  getUnitInfo() {
    return this.apiQTHT.appQthtApiSysUnitExtandGetUnitInfo();
  }
  selectAll(): Observable<DanhGiaModelListApiResponse> {
    return this.api.appVCVNApiDanhGiaSelectAll();
  }
  selectOne(danhGiaId: number): Observable<DanhGiaModelApiResponse> {
    return this.api.appVCVNApiDanhGiaSelectOne(danhGiaId);
  }
 
  import(
    body: DanhGiaModel[] | undefined
  ): Observable<ResultImportModelApiResponse> {
    return this.api.appVCVNApiDanhGiaImport(body);
  }
  delete(danhGiaId: number): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiDanhGiaDelete(danhGiaId);
  }
  update(body: DanhGiaModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiDanhGiaUpdate(body);
  }
  updateTrangThai(
    body: DanhGiaModel | undefined
  ): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiDanhGiaUpdateTrangThai(body);
  }
  create(body: DanhGiaModel | undefined): Observable<Int32ApiResponse> {
    return this.api.appVCVNApiDanhGiaCreate(body);
  }
  selectAllRecordBase(): Observable<DanhGiaModelListApiResponse> {
    return this.api.appVCVNApiRecordExtandSelectAllBase();
  }
  selectAllBy(menuName: string): Observable<DanhGiaModelListApiResponse> {
    return this.api.appVCVNApiDanhGiaExtandSelectAllBy(menuName);
  }
  getImage(danhGiaId: number): Observable<StringApiResponse> {
    return this.api.appVCVNApiDanhGiaExtandGetImage(danhGiaId);
  }
  getNoiDung(danhGiaId: number): Observable<StringApiResponse> {
    return this.api.appVCVNApiDanhGiaExtandGetNoiDung(danhGiaId);
  }
}
