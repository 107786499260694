import { MenuComponent } from './menu/menu.component';
import { NgModule } from '@angular/core';
import { PagesRoutingModule } from './pages-routing.module';
import { ThemeModule } from '../theme.module';
import { HomeComponent } from './home-component/home.component';

@NgModule({
  imports: [PagesRoutingModule, ThemeModule],
  declarations: [MenuComponent, HomeComponent],

  providers: [],
})
export class PagesModule {}
